import React from "react";
import { Autocomplete as MaterialAutocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import styles from "./autocomplete.module.scss";
import classNames from "classnames";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import { Checkbox } from "components/commons";

import { useCallback } from "react";
import { useMemo } from "react";

const Autocomplete = ({
  value = null || [],
  options = [],
  onChange,
  name,
  placeholder = "Select",
  disabled,
  onInputChange,
  multiple = false,
  hasAll = false,
  className,
  capitalize = true,
  error,
  isAll,
  toggleAll,
}) => {
  const onChangeCb = useCallback(
    (e, valObj) => {
      if (onChange) {
        return onChange(name, { ...valObj, value: valObj?.value || null });
      }
    },
    [onChange, name]
  );

  const isSelectedAll = useMemo(() => {
    if (toggleAll) {
      return isAll;
    }
    if (multiple && hasAll) {
      if (value.length === options.length) {
        return true;
      }
    }
    return false;
  }, [value, multiple, hasAll, options.length, isAll, toggleAll]);

  const dropdownOptions = useMemo(() => {
    if (hasAll) {
      return [{ label: "All", value: "all" }, ...options];
    }
    return options;
  }, [options, hasAll]);

  const renderValue = useMemo(() => {
    const names = options
      .filter(({ value: v }) => {
        return value.includes(v);
      })
      .map(({ label }) => label);
    return !value.length ? "All" : names.join(", ");
  }, [value, options]);

  if (multiple) {
    return (
      <div className={className}>
        <FormControl
          error={error}
          variant="outlined"
          className={classNames(styles.multipleAutocomplete)}
        >
          <Select
            className={classNames({
              [`${styles.error}`]: error,
              [styles.disabled]: disabled,
            })}
            disabled={disabled}
            error={error}
            name={name}
            multiple
            value={isAll ? ["all"] : value || []}
            // placeholder={placeholder}
            onChange={
              hasAll
                ? (obj) => {
                    const newVal = obj?.target?.value;
                    if (toggleAll) {
                      const isAllSelected =
                        options.length === newVal.length || newVal.includes("all");
                      onChange(name, {
                        value: isAllSelected ? [] : newVal.filter((value) => value !== "all"),
                        isAll: isAll ? false : isAllSelected,
                      });
                    } else if (
                      value.includes("all") ||
                      (isSelectedAll && value.length !== options.length - 1) ||
                      (value.length === 1 && value[0] === "all")
                    ) {
                      onChange(name, {
                        value: isSelectedAll ? [] : options.map((option) => option.value),
                      });
                    } else {
                      onChange(name, {
                        value: value,
                      });
                    }
                  }
                : onChange
            }
            renderInput={() => {
              if (toggleAll && !value.length) {
                return "All";
              }
              if (!value.length || !value) {
                return placeholder;
              }
            }}
            renderValue={(selected) => {
              if (toggleAll) {
                return renderValue;
              }
              if (isSelectedAll) {
                return "All";
              }
              let values = [...selected];
              const index = values.indexOf("all");
              if (index !== -1) {
                values = ["all"];
              }

              const normalizeValue = {};
              dropdownOptions.forEach((o) => {
                normalizeValue[o.value] = o.label;
              });

              return (
                <span className={capitalize ? styles.selectedItems : null}>
                  {values.map((v) => normalizeValue[v]).join(", ")}
                </span>
              );
            }}
          >
            {dropdownOptions.map((name) => (
              <MenuItem key={name.value} value={name.value}>
                <Checkbox value={isSelectedAll || value.indexOf(name.value) > -1} />
                <ListItemText primary={name.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  } else {
    return (
      <div className={className}>
        <MaterialAutocomplete
          error={error}
          value={value}
          getOptionSelected={(option, value) => option.value === value}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              const label = options.find((o) => o.value === option)?.label;
              if (label) {
                return label;
              }
            }
            return option.label ? option.label : "";
          }}
          className={classNames(styles.autocomplete, {
            [styles.disabled]: disabled,
          })}
          options={options}
          onChange={onChangeCb}
          renderInput={(params) => {
            return <TextField {...params} variant="outlined" placeholder={placeholder} />;
          }}
          ListboxProps={{ style: { fontSize: "14px" } }}
          disabled={disabled}
          onInputChange={onInputChange}
        />
      </div>
    );
  }
};

export default Autocomplete;
