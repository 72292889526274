const env = {
  ID: "staging",
  API_URL: "https://staging.api.locq.com",
  WEB_SOCKET_URL: "wss://5jhgzsdcke.execute-api.ap-southeast-1.amazonaws.com/staging",
  GOOGLE_MAP_API_KEY: "AIzaSyAIPhiJk9EgZDcwgrfD4n-kATpZzWKVt7w",
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyCK-35X65kMYzHcOi8eVBJ4IU2N6HipG0g",
    authDomain: "pricelocq-business-dev.firebaseapp.com",
    databaseURL: "https://pricelocq-business-dev-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "pricelocq-business-dev",
    storageBucket: "pricelocq-business-dev.appspot.com",
    messagingSenderId: "972496080406",
    appId: "1:972496080406:web:97f238c01b9e59d1e2af37",
  },
  APP_FIREBASE_CONFIG: {
    apiKey: "AIzaSyAgbfBt_Lz0C8EwjxrNI9J-vtfPib6BvHw",
    authDomain: "pivot-seaoil-ap-test.firebaseapp.com",
    databaseURL: "https://pivot-seaoil-ap-test.firebaseio.com",
    projectId: "pivot-seaoil-ap-test",
    storageBucket: "pivot-seaoil-ap-test.appspot.com",
    messagingSenderId: "836556693696",
    appId: "1:836556693696:web:0e82436e0ffdea48720d25",
    measurementId: "G-QVN8590QCX",
    name: "mobile",
  },
};

export default env;
