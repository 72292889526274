import { Path } from "enums";
import CompetitorListModule from "modules/competitor-list/competitor-list.module";
import CompetitorAddModule from "modules/competitor-list/competitor-add-list/competitor-add.module";
import CompetitorEditModule from "modules/competitor-list/competitor-edit-list/competitor-edit.module";
import CompetitorViewModule from "modules/competitor-list/competitor-view-list/competitor-view.module";
import React from "react";
import { Redirect } from "react-router-dom";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";

const CompetitorListPage = () => {
  return (
    <Switch>
      <Route exact path={Path.CompetitorList} component={CompetitorListModule} />
      <Route exact path={Path.AddCompetitor} component={CompetitorAddModule} />
      <Route exact path={Path.EditCompetitor} component={CompetitorEditModule} />
      <Route exact path={Path.ViewCompetitor} component={CompetitorViewModule} />
      <Redirect to={Path.Competitor} />
    </Switch>
  );
};

export default CompetitorListPage;
