import React from "react";
import classNames from "classnames";
import styles from "./image.module.scss";
import { CircularProgress } from "@material-ui/core";

const Image = ({ style, className, src, alt, onClick, loading }) => {
  if (loading) {
    return (
      <div style={style} className={classNames(className, styles.image, "flex items-center")}>
        <CircularProgress
          style={{
            fontSize: "20px",
            margin: "auto",
          }}
          size={50}
        />
      </div>
    );
  }
  return (
    <img
      style={style}
      className={classNames(className, styles.image)}
      onClick={onClick}
      src={src}
      alt={alt}
    />
  );
};

export default Image;
