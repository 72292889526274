import { Image, Text, PopOverMenu, Button } from "components/commons";
import { dateTimeFormat, formatCurrency, formatDate } from "utils";
import styles from "./station-pricing.module.scss";
import { capitalizeWord } from "utils/text.utils";
import locale from "localization";
import { DateTime } from "enums";

export const mapDataToList = ({ priceCaptures = {}, showImage }) => {
  const {
    brand = {},
    capturedAt,
    priceCaptureId,
    station,
    province,
    city,
    priceCaptureProducts,
    priceCaptureStations,
    imageUrl,
    email,
    brandName,
  } = priceCaptures;
  const optionsData = priceCaptureStations?.map((data) => {
    return {
      content: <Text>{capitalizeWord(data.name)}</Text>,
    };
  });
  const optionsCompetitor = [...optionsData];
  return {
    brandName: brand
      ? brandName
        ? `${locale.others} - ${brandName}`
        : brand?.brandName ?? ""
      : locale.seaoil,
    uploadId: (
      <>
        <Text>{priceCaptureId}</Text>
        <Text className={styles?.subBusinessId}>{dateTimeFormat(capturedAt)}</Text>
      </>
    ),
    stationName: (
      <Text>
        {station?.name
          ? `${locale.seaoil} ${capitalizeWord(station?.name.replace("SEAOIL", ""))}`
          : locale.NA}
      </Text>
    ),
    competitorOf:
      priceCaptureStations.length === 0 ? (
        locale.NA
      ) : priceCaptureStations.length > 2 ? (
        <PopOverMenu
          position="right"
          options={optionsCompetitor}
          mouseOverTrigger
          header={locale.competitorStations}
        >
          <Button style={{ cursor: "pointer" }} link>
            {locale.viewAll}
          </Button>
        </PopOverMenu>
      ) : (
        <>
          {priceCaptureStations?.map((data) => (
            <Text>{capitalizeWord(data.name)}</Text>
          ))}
        </>
      ),
    province,
    city,
    product: (
      <>
        {priceCaptureProducts.map((data) => (
          <Text>{capitalizeWord(data.productName)}</Text>
        ))}
      </>
    ),
    pumpPrice: (
      <>
        {priceCaptureProducts.map((data) => (
          <Text>{formatCurrency(data.price)}</Text>
        ))}
      </>
    ),
    uploaderEmail: <>{email ? <Text>{email}</Text> : <Text>{locale?.noEmail}</Text>}</>,
    priceCapture: (
      <>
        <Image src={imageUrl} className={styles?.imageSize} onClick={() => showImage(imageUrl)} />
      </>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { areaGroup = {}, province = {}, city = {}, brand = {}, competitorOf, ...fs } = filterState;
  const selectedBrands = brand?.value?.map((item) => {
    return item.value;
  });

  const selectedCompetitorOf = competitorOf?.value?.map((item) => {
    return item.value;
  });
  return {
    ...fs,
    startDate: fs.startDate ? formatDate(fs.startDate, DateTime.C) : "",
    endDate: fs.endDate ? formatDate(fs.endDate, DateTime.D) : "",
    areaGroup: areaGroup.isAll ? "" : areaGroup?.value?.join(","),
    province: province.isAll ? "" : province?.value?.join(","),
    city: city.isAll ? "" : city?.value?.join(","),
    brand: brand.isAll ? "" : selectedBrands.join(","),
    competitorOf: competitorOf.isAll ? "" : selectedCompetitorOf.join(","),
  };
};
