import React from "react";
import { useModal } from "hooks";
import locale from "localization";
import styles from "./competitor-add.module.scss";
import { FormMode } from "enums";
import { Path } from "enums";
import { Intro, BackLink } from "components/commons";
import CompetitorDetailsModule from "../competitor-details/competitor-details.module";

const CompetitorAddModule = () => {
  const successModal = useModal();

  return (
    <div>
      <BackLink text={locale.competitorList} path={Path.CompetitorList} />
      <div className={styles.intro}>
        <Intro black title={locale.createNewCompetitor} />
        <CompetitorDetailsModule pageMode={FormMode.Add} {...successModal} />
      </div>
    </div>
  );
};

export default CompetitorAddModule;
