import { ApiPath } from "enums";
import { Get, Post, Put } from "utils";

export const getStations = async (query) => {
  const res = await Get(`${ApiPath.GetStations}`, { ...query });
  return res.data;
};

export const getStation = async ({ stationId, query }) => {
  const res = await Get(`${ApiPath.GetStations}/station-id/${stationId}`, { ...query });
  return res.data;
};

export const getStationByStationCode = async ({ stationCode, query }) => {
  const res = await Get(`${ApiPath.Station}/v2/price-tool/${stationCode}`, { ...query });
  return res.data;
};

export const createStation = async ({ ...query }) => {
  const res = await Post(`${ApiPath.GetStations}`, query);
  return res.data;
};

export const updateStation = async ({ stationCode, ...body }) => {
  const res = await Put(`${ApiPath.GetStations}/${stationCode}`, body);
  return res.data;
};

export const validateStationCode = async ({ stationCode, ...query }) => {
  const res = await Get(`${ApiPath.GetStations}/station-code/${stationCode}`, query);
  return res.data;
};

export const getStationCode = async ({ stationCode }) => {
  const res = await Get(`${ApiPath.GetStations}/code/v2`, { stationCode });
  return res.data;
};
