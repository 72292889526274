import { capitalize } from "utils";

export const competitorFormPost = (data, productsData) => {
  return {
    brandName: capitalize(data?.brandName),
    brandProducts: [...productsData],
  };
};

export const competitorFormGet = (data) => {
  return {
    brandName: { value: data?.brandName },
  };
};
