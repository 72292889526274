import React, { useCallback, useContext, useState } from "react";
import { useApi, useMount } from "hooks";
import { getUser } from "apis";
import { AppContext, FeatureFlagContext, UserContext } from "contexts";
import { Route, Switch } from "react-router-dom";
import { Path } from "enums";
import AppLoader from "components/commons/app-loader/app-loader";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import Header from "components/commons/header/header";
import Sidebar from "components/commons/sidebar/sidebar";
import StationPricingPage from "./station-pricing.page";
import AccessAuthorizationPage from "./access-authorization.page";
import CompetitorListPage from "./competitor-list.page";
import StationListPage from "./station-list.page";

const AuthorizedPage = () => {
  const { appState } = useContext(AppContext);
  const { setUserEmail } = useContext(FeatureFlagContext);
  const [user, setUser] = useState();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { request: getUserRequest, loading: fetchingUser } = useApi({
    api: getUser,
    pageError: true,
  });

  useMount(() => {
    fetchUser();
  });

  const fetchUser = useCallback(async () => {
    const res = await getUserRequest();
    setUser(res);
    setUserEmail(res.email);
  }, [getUserRequest, setUserEmail]);

  return (
    <UserContext.Provider value={{ user, fetchUser, loading: fetchingUser }}>
      {fetchingUser || appState?.loading || !user ? (
        <AppLoader />
      ) : (
        <>
          <Header onClick={() => setIsDrawerOpen(!isDrawerOpen)} />
          <Sidebar />
          <div
            style={{
              marginLeft: "196px",
              marginTop: "60px",
              padding: "27px 36px",
              minWidth: "1170px",
              overflowX: "auto",
              minHeight: "100vh",
              backgroundColor: "#fff",
            }}
          >
            <Switch>
              <Route exact path={Path.Slash} component={StationPricingPage} />
              <Route path={Path.AccessAuthorization} component={AccessAuthorizationPage} />
              <Route path={Path.Competitor} component={CompetitorListPage} />
              <Route path={Path.Station} component={StationListPage} />
              <Redirect to={Path.Slash} />
            </Switch>
          </div>
        </>
      )}
    </UserContext.Provider>
  );
};

export default AuthorizedPage;
