import React from "react";

import { Intro } from "components/commons";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import useDataTable from "hooks/useDataTable";
import locale from "localization";

import { mapFilterToRequest, mapDataToList } from "./station-list.mapper";
import { columns } from "./station-list-column";
import { initialFilterState } from "./station-list-filter.state";
import { useModal, useRouter } from "hooks";
import CompetitorListFilter from "./station-list-filter";
import { getStations } from "apis/station.api";
import { Path } from "enums";

import { ConfirmModal } from "components/modals";
import { generateReport } from "apis/generate-report.api";
import useExport from "hooks/useExport";

const StationListModule = () => {
  const confirmModal = useModal();

  const { history } = useRouter();

  const handleViewStation = (params) => {
    history.push(Path.ViewStation, {
      params,
    });
  };

  const { filter, search, table } = useDataTable({
    api: {
      api: getStations,
      params: {
        // portal: Portal.PRICETRACQ,
        // profileType: ProfileType.PT,
      },
      mapper: (param) => {
        return param;
      },
    },
    filter: { initialState: initialFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "stations",
      mapper: (stations) =>
        mapDataToList({
          stations,
          handleViewStation,
        }),
      columns,
    },
  });

  const exportReport = useExport({
    api: generateReport,
    reportType: "station-list",
    mappedFilterState: { ...filter?.mappedFilterState, searchKey: search?.searchKey },
  });

  return (
    <>
      <ConfirmModal {...confirmModal} />
      <Intro
        title={locale.seaoilStationList}
        subtitle={locale.viewAndTrackStationsInYourArea}
        actionText={locale.addNewSeaOilStation}
        actionOnClick={() => history.push(Path.AddStation)}
      />
      <CompetitorListFilter {...filter} {...search} {...exportReport} />
      <DataTableV2 {...table} paginationV2 />
    </>
  );
};

export default StationListModule;
