import { DateTime } from "enums";
import moment from "moment";

export const dateFormat = (date) => {
  return moment(date).format("DD MMM yyyy");
};

export const dateTimeFormat = (dateTime) => {
  return moment(dateTime).format("DD MMM yyyy hh:mma");
};

export const formatDate = (date, format = DateTime.A) => {
  return moment(date).format(format);
};
