import classNames from "classnames";
import React from "react";
import styles from "./title.module.scss";

const HeaderTag = ({ xsmall, small, medium, large, children, ...props }) => {
  if (xsmall) {
    return <h4 {...props}>{children}</h4>;
  }
  if (small) {
    return <h3 {...props}>{children}</h3>;
  }
  if (medium) {
    return <h2 {...props}> {children}</h2>;
  }
  return <h1 {...props}>{children}</h1>;
};

const Title = ({ className, bold, black, children, subText, subTextClassName, ...props }) => {
  return (
    <>
      <HeaderTag
        className={classNames(className, styles.title, {
          [`${styles.indigo}`]: !black,
          [`${styles.night}`]: black,
          [`${styles.subTextSpacing}`]: subText,
        })}
        {...props}
      >
        {children}
        {subText && (
          <div className={classNames(styles.subHeaderText, subTextClassName)}>
            <span>{subText}</span>
          </div>
        )}
      </HeaderTag>
    </>
  );
};

export default Title;
