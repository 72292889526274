import { Path } from "enums";
import StationListModule from "modules/station-list/station-list.module";
import StationAddModule from "modules/station-list/station-add-list/station-add.module";
import StationEditModule from "modules/station-list/station-edit-list/station-edit.module";
import StationViewModule from "modules/station-list/station-view-list/station-view.module";
import React from "react";
import { Redirect } from "react-router-dom";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";

const StationListPage = () => {
  return (
    <Switch>
      <Route exact path={Path.StationList} component={StationListModule} />
      <Route exact path={Path.AddStation} component={StationAddModule} />
      <Route exact path={Path.EditStation} component={StationEditModule} />
      <Route exact path={Path.ViewStation} component={StationViewModule} />
      <Redirect to={Path.Station} />
    </Switch>
  );
};

export default StationListPage;
