export const initialFilterState = () => ({
  startDate: null,
  endDate: null,
  areaGroup: { value: [], isAll: true },
  province: { value: [], isAll: true },
  city: { value: [], isAll: true },
  brand: { value: [], isAll: true },
  competitorOf: { value: [], isAll: true },
  searchKey: "",
  uploadIds: "",
  page: 1,
  perPage: 10,
});
