const ProductCodeEnum = {
  DIESEL: "diesel", //extreme diesel
  GAS91: "gas91", // extreme u
  GAS95: "gas95", // extreme 95
  GAS97: "gas97", // extreme 97
  GAS105: "gas105", // extreme 105
  KEROSENE: "kerosene",
  LPG: "lpg",
};

export default ProductCodeEnum;
