import React from "react";
import { Text } from "..";
import Button from "../button/button";
import Title from "../title/title";
import styles from "./intro.module.scss";
import locale from "localization";
import { DateTime } from "enums";
import moment from "moment";

const Intro = ({
  introStyle,
  titleStyle,
  textStyle,
  title,
  subtitle,
  actionText,
  actionClassName,
  actionOnClick,
  actionOutlined,
  actionStartIcon,
  actionDisabled,
  actionLoading,
  actionSecondaryText,
  actionSecondaryClassName,
  actionSecondaryOnClick,
  actionSecondaryStartIcon,
  actionSecondaryDisabled,
  isLastEdit = false,
  isLastEditDate,
  black,
  style,
}) => {
  return (
    <div className={introStyle || styles.intro} style={style}>
      <div>
        <Title className={titleStyle || styles.title}>{title}</Title>
        <Text className={textStyle}>{subtitle}</Text>
      </div>
      <div className={styles.buttonContainer}>
        {actionSecondaryText && (
          <div className={styles.secondaryButtonContainer}>
            <Button
              startIcon={actionSecondaryStartIcon}
              onClick={actionSecondaryOnClick}
              className={actionSecondaryClassName}
              disabled={actionSecondaryDisabled}
            >
              {actionSecondaryText}
            </Button>
          </div>
        )}
        {isLastEdit && (
          <Text label>{`${locale.lastEdited} ${moment(isLastEditDate).format(DateTime.G)}`}</Text>
        )}
        {actionText && (
          <div className={styles.primaryButtonContainer}>
            <Button
              startIcon={actionStartIcon}
              primary={!actionOutlined}
              outline={actionOutlined}
              onClick={actionOnClick}
              className={actionClassName}
              disabled={actionDisabled}
              loading={actionLoading}
            >
              {actionText}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Intro;
