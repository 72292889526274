import React, { useCallback, useImperativeHandle, useRef, forwardRef } from "react";
import { CircularProgress, TextField as MaterialTextField } from "@material-ui/core";
import styles from "./textfield.module.scss";
import InputAdornment from "@material-ui/core/InputAdornment";
import classNames from "classnames";

const TextField = (
  {
    name,
    type = "text",
    value = "",
    variant = "outlined",
    placeholder = "",
    error,
    suffix,
    onChange,
    onKeyUp,
    onEnter,
    prefix,
    multiline,
    rowsMax,
    disabled,
    maxLength,
    onBlur,
    upperCase,
    onFocus,
    className,
    helperText = "",
    loading,
    fontColor = "#000",
    inputDisabled = false,
  },
  ref
) => {
  const inputRef = useRef();

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current.focus();
    },
  }));

  const onChangeCb = useCallback(
    (e) => {
      if (onChange) {
        let value = e?.target.value.toString();
        if (upperCase) {
          value = value.toUpperCase();
        }

        onChange(name, { value });
      }
    },
    [onChange, name, upperCase]
  );

  const onKeyUpCb = useCallback(
    (e) => {
      if (onKeyUp) {
        onKeyUp(e?.target.value.trim(), e?.key);
      }
      if (e.key === "Enter" && onEnter) {
        onEnter(e?.target.value.trim());
      }
    },
    [onKeyUp, onEnter]
  );

  const onBlurCb = useCallback(
    (e) => {
      if (onBlur) {
        onBlur(name, { value: e?.target.value });
      }
    },
    [onBlur, name]
  );

  const onFocusCb = useCallback(
    (e) => {
      if (onFocus) {
        onFocus(e);
      }
    },
    [onFocus]
  );

  const inputProps = {
    InputProps: {
      maxLength,
      startAdornment: prefix ? <InputAdornment position="start">{prefix}</InputAdornment> : null,
      endAdornment:
        suffix || loading ? (
          <InputAdornment position="end">
            {loading ? <CircularProgress className={styles.loader} /> : suffix}
          </InputAdornment>
        ) : null,
    },
  };
  return (
    <div className={styles.container}>
      <MaterialTextField
        inputRef={inputRef}
        className={classNames(styles.input, className, {
          [`${styles.multiline}`]: multiline,
          [`${styles.disabled}`]: disabled,
        })}
        error={error}
        type={type}
        value={value}
        variant={variant}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChangeCb}
        onKeyUp={onKeyUpCb}
        multiline={multiline}
        rowsMax={rowsMax}
        onBlur={onBlurCb}
        onFocus={onFocusCb}
        loading={loading}
        helperText={helperText}
        maxLength={maxLength}
        inputProps={{
          disabled: inputDisabled ?? disabled,
          maxLength,
          style: {
            color: fontColor,
          },
        }}
        {...inputProps}
      ></MaterialTextField>
    </div>
  );
};

export default forwardRef(TextField);
