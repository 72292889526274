import React, { useState } from "react";
import Popover from "@material-ui/core/Popover";
import styles from "./pop-over.module.scss";
import classNames from "classnames";

const PopOver = ({ children, content, simple, className, onClose }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(undefined);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(undefined);
    if (onClose) {
      onClose();
    }
  };

  return (
    <div>
      <div className="pointer" onClick={handleClick}>
        {children}
      </div>
      <Popover
        id={open ? "pop-over-content" : undefined}
        open={open}
        anchorEl={anchorEl}
        className={classNames(classNames(styles.popoverContainer, className))}
        onClose={handleClose}
        // keepMounted
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {!simple && <div className={styles.arrow}></div>}

        <div className={classNames(styles.content, className)}>{content}</div>
      </Popover>
    </div>
  );
};

export default PopOver;
