import { DropdownPaginated } from "components/commons";
import React, { useCallback } from "react";
import { getStations } from "apis";
import locale from "localization";
import { useApi } from "hooks";
import { capitalizeWord } from "utils/text.utils";

const SelectStations = ({ placeholder = locale.searchOrSelect, disableAllOption, ...props }) => {
  const {
    request,
    loading,
    result = { brands: [], count: 0 },
  } = useApi({
    api: getStations,
    pageError: true,
  });

  const fetch = useCallback(
    async (params) => {
      return await request({
        order: "asc",
        status: "active",
        perPage: 999,
        ...params,
      });
    },
    [request]
  );

  return (
    <DropdownPaginated
      {...props}
      request={async (params) => {
        return await fetch(params);
      }}
      total={result?.stations?.length}
      loading={loading}
      formatRequest={(result) => {
        const { stations = [] } = result || {};
        if (stations.length > 0) {
          const options = stations.map((d) => {
            return {
              label: `${locale.seaoil} ${capitalizeWord(d.name.replace("SEAOIL", ""))}`,
              value: d.stationId,
            };
          });
          return options;
        }
        return [];
      }}
      placeholder={placeholder}
      {...(!disableAllOption
        ? {
            allOption: [
              {
                label: "All",
                value: "all",
              },
            ],
          }
        : [])}
    />
  );
};

export default SelectStations;
