import locale from "localization";
import Validation from "utils/validation.utils";

export const initialState = (state = {}) => {
  const {
    brandName = {
      value: "",
    },
  } = state;

  return {
    brandName: {
      name: "brandName",
      required: true,
      validations: [Validation.required({ noMessage: true }), Validation.maxlength(150)],
      label: locale.brand,
      placeholder: locale.brandName,
      horizontal: true,
      ...brandName,
    },
  };
};
