import React from "react";
import { useHistory } from "react-router-dom";
import locale from "localization";
import styles from "./competitor-view.module.scss";
import { FormMode } from "enums";
import { Path } from "enums";
import { Intro, BackLink } from "components/commons";
import CompetitorDetailsModule from "../competitor-details/competitor-details.module";

const CompetitorViewModule = (props) => {
  const history = useHistory();
  const brandId = props.location.state ? props.location.state.brandId : "";
  const updatedAt = props.location.state ? props.location.state.updatedAt : "";
  return (
    <div>
      <BackLink text={locale.competitorList} path={Path.CompetitorList} />
      <div className={styles.intro}>
        <Intro
          title={locale.viewCompetitorDetails}
          actionText={locale.edit}
          isLastEdit={true}
          isLastEditDate={updatedAt}
          actionOnClick={() => {
            history.push(Path.EditCompetitor, {
              brandId,
              updatedAt,
            });
          }}
          actionOutlined
          actionClassName={styles.primaryEditButton}
          actionStartIcon={<span className="icon-pencil" />}
        />
        <CompetitorDetailsModule pageMode={FormMode.View} brandId={brandId} />
      </div>
    </div>
  );
};

export default CompetitorViewModule;
