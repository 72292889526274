import React from "react";

import styles from "./station-list-filter.module.scss";

import { Field, SearchField, Button } from "components/commons";
import locale from "localization";

const StationPricingFilter = ({
  searchKey,
  modifySearch,
  applySearch,
  applyClearSearch,

  exporting,
  exportReport,
}) => {
  return (
    <div className={styles?.container}>
      <div className={styles?.filter}>
        <Field className={styles.search}>
          <SearchField
            restrict={false}
            searchWithSymbols
            placeholder={[locale.stationCode, locale.stationName].join(", ")}
            value={searchKey}
            onChange={(_, { value }) => {
              modifySearch(value);
            }}
            onEnter={applySearch}
            onClear={applyClearSearch}
          />
        </Field>
        <Field className={styles.downloadButton}>
          <Button
            startIcon={<span className="icon-download" />}
            onClick={exportReport}
            exportButton
            loading={exporting}
          >
            {locale.export}
          </Button>
        </Field>
      </div>
    </div>
  );
};

export default StationPricingFilter;
