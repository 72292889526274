import React, { useCallback, useState } from "react";

import { Image, Intro } from "components/commons";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import useDataTable from "hooks/useDataTable";
import locale from "localization";

import { mapFilterToRequest, mapDataToList } from "./station-pricing.mapper";
import { columns } from "./station-pricing-column";
import { initialFilterState } from "./station-pricing-filter.state";
import StationPricingFilter from "./station-pricing-filter";
import { getStationPricing } from "apis";
import { useModal, useMount, useApi } from "hooks";
import PreviewModal from "components/commons/preview-modal/preview-modal";

import styles from "./station-pricing.module.scss";

import { ImageOutlined } from "@material-ui/icons";
import StationMapView from "./station-map-view";
import moment from "moment";
import { formatDate } from "utils";
import { DateTime } from "enums";
import useExport from "hooks/useExport";
import { generateReport } from "apis/generate-report.api";
import { CircularProgress } from "@mui/material";

const StationPricingModule = () => {
  const previewImageModal = useModal();
  const [imageUrl, setImageUrl] = useState(null);
  const [openMap, setOpenMap] = useState(false);

  const getImageName = useCallback((image) => {
    if (image) {
      const filePart = image.split("/");
      return filePart?.[filePart?.length - 1];
    }
    return "";
  }, []);

  const showImage = useCallback(
    (imageUrl) => {
      setImageUrl(imageUrl);
      previewImageModal?.show();
    },
    [previewImageModal]
  );

  const stationMapPricing = useApi({
    api: getStationPricing,
    params: {
      startDate: formatDate(moment().weekday(-14), DateTime.C),
      endDate: formatDate(moment(), DateTime.D),
      page: 1,
      perPage: 999,
    },
  });

  const { filter, search, table } = useDataTable({
    api: {
      api: async (params) => {
        stationMapPricing.request({
          ...params,
          page: 1,
          perPage: 999,
        });
        return await getStationPricing(params);
      },
      mapper: (param) => {
        return param;
      },
    },
    filter: { initialState: initialFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "priceCaptures",
      mapper: (priceCaptures) =>
        mapDataToList({
          priceCaptures,
          showImage,
        }),
      columns,
    },
  });

  const exportReport = useExport({
    api: generateReport,
    reportType: "station-pricing",
    mappedFilterState: { ...filter?.mappedFilterState, searchKey: search?.searchKey },
  });

  useMount(() => {
    stationMapPricing?.request();
  });

  // const updateMap = useCallback(() => {
  //   const { startDate, endDate, city, province, areaGroup, brands, searchKey } =
  //     filter?.filterState;

  // stationMapPricing?.request({
  //   startDate: startDate ?? formatDate(moment().weekday(-14), DateTime.C),
  //   endDate: endDate ?? formatDate(moment(), DateTime.D),
  //   brand: brands?.value?.map((data) => data.value).join(","),
  //   city: city?.value?.map((data) => data.value).join(","),
  //   province: province?.value?.map((data) => data.value).join(","),
  //   areaGroup: areaGroup?.value?.join(","),
  //   searchKey,
  //   page: 1,
  //   perPage: 999,
  // });
  // }, [filter?.filterState, stationMapPricing]);
  return (
    <>
      <PreviewModal
        {...previewImageModal}
        title={
          <div
            className="flex items-center"
            style={{
              color: "#fff",
            }}
          >
            <ImageOutlined
              style={{
                color: "#fff",
                marginRight: "10px",
              }}
            />
            {getImageName(imageUrl)}
          </div>
        }
      >
        <Image src={imageUrl} className={styles?.viewPhoto} />
      </PreviewModal>
      <Intro title={locale.stationPricing} subtitle={locale.viewTrackCashActivities} />
      <StationPricingFilter
        {...filter}
        {...search}
        {...exportReport}
        openMap={openMap}
        setOpenMap={setOpenMap}
        // updateMap={updateMap}
      />
      {openMap ? (
        table.loading ? (
          <CircularProgress />
        ) : (
          <StationMapView stations={stationMapPricing?.result?.priceCaptures} />
        )
      ) : (
        <DataTableV2 {...table} paginationV2 />
      )}
    </>
  );
};

export default StationPricingModule;
