import React, { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "./pop-over-menu.module.scss";
import classNames from "classnames";
import { Text } from "components/commons";

const PopOverMenu = ({
  options = [],
  children,
  position,
  disabledClick = false,
  setActiveClicked,
  style,
  header = null,
  mouseOverTrigger = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    const target = event.currentTarget;
    if (!disabledClick) {
      setAnchorEl(target);
      if (setActiveClicked) {
        setActiveClicked(target);
      }
    }
  };

  const handleClose = (option = null) => {
    if (option?.onClick) {
      option.onClick();
    }
    if (setActiveClicked) {
      setActiveClicked(false);
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <div
        aria-controls="pop-over-menu"
        aria-haspopup="true"
        onClick={handleClick}
        onMouseOver={mouseOverTrigger && handleClick}
        className={styles.container}
        style={style}
      >
        {children}
      </div>
      <Menu
        id="pop-over-menu"
        anchorEl={anchorEl}
        className={classNames(styles.popoverContainer, {
          [styles.bottom]: position === "bottom",
          [styles.top]: position === "top",
          [styles.right]: position === "right",
        })}
        keepMounted
        open={open}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
      >
        {header && (
          <MenuItem className={styles.content} disabled={false}>
            <Text strong style={{ color: "#504e8b" }}>
              {header}
            </Text>
          </MenuItem>
        )}

        {options.map((option, i) => {
          if (option.removable && option.disabled) {
            return "";
          } else {
            return (
              <MenuItem
                key={i}
                onClick={() => handleClose(option)}
                className={styles.content}
                disabled={option.disabled}
              >
                {option.content}
              </MenuItem>
            );
          }
        })}
      </Menu>
    </div>
  );
};

export default PopOverMenu;
