import React from "react";
import { TextField } from "..";
import styles from "./search-field.module.scss";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { isAlphaNumeric, isAlphaNumericWithSymbols } from "utils/text.utils";

const SearchField = ({
  name,
  onClear,
  onChange,
  restrict = true,
  searchWithSymbols = false,
  ...props
}) => {
  // TODO: improve logic on allowing symbols or not
  return (
    <TextField
      onChange={(name, { value }) => {
        if (searchWithSymbols) {
          if (restrict && !isAlphaNumericWithSymbols(value)) {
            return;
          }
        } else {
          if (restrict && !isAlphaNumeric(value)) {
            return;
          }
        }

        onChange(name, { value });
      }}
      {...props}
      prefix={<SearchIcon className={styles.searchIcon} />}
      suffix={
        props.value ? (
          <CloseIcon
            className={styles.clearIcon}
            onClick={() => {
              if (onClear) {
                onClear();
              } else {
                onChange(name, { value: "" });
                props.onEnter();
              }
            }}
          />
        ) : null
      }
    />
  );
};

export default SearchField;
