import { UserStatus, FuelCategoryEnum, ProductCodeEnum } from "enums";

export const prettifyUserStatus = (type) => {
  return (
    {
      [UserStatus.Active]: "Active",
      [UserStatus.Deactivated]: "Deactivated",
      [UserStatus.Deleted]: "Deleted",
    }[type] || ""
  );
};

export const prettifyFuelCategory = (type) => {
  return (
    {
      [FuelCategoryEnum.GASOLINE]: "Gasoline",
      [FuelCategoryEnum.DIESEL]: "Diesel",
      [FuelCategoryEnum.KEROSENE]: "Kerosene",
      [FuelCategoryEnum.LPG]: "LPG",
    }[type] || ""
  );
};

export const prettifyProductCode = (type) => {
  return (
    {
      [ProductCodeEnum.DIESEL]: "Diesel",
      [ProductCodeEnum.GAS91]: "Extreme U",
      [ProductCodeEnum.GAS95]: "Extreme 95",
      [ProductCodeEnum.GAS97]: "Extreme 97",
      [ProductCodeEnum.GAS105]: "Extreme 105",
      [ProductCodeEnum.GAS105]: "Extreme 105",
      [ProductCodeEnum.KEROSENE]: "Kerosene",
      [ProductCodeEnum.LPG]: "LPG",
    }[type] || ""
  );
};
