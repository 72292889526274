import Validation from "utils/validation.utils";
import locale from "localization";

export const initialState = (state = {}) => {
  const {
    email = {
      value: "",
      disabled: false,
    },
  } = state;
  return {
    email: {
      name: "email",
      placeholder: locale.enterEmailAddressHere,
      validations: [Validation.required({ noMessage: true }), Validation.emailAddress()],
      maxLength: 75,
      label: locale.emailAddress,
      required: true,
      ...email,
    },
  };
};
