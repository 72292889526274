import React from "react";

import styles from "./competitor-list-filter.module.scss";

import { Field, Filter, SearchField, Autocomplete, Button } from "components/commons";
import { prettifyFuelCategory, prettifyProductCode } from "utils/pretty.utils";
import locale from "localization";
import { FuelCategoryEnum, ProductCodeEnum } from "enums";

const StationPricingFilter = ({
  filterState,
  modifyFilter,
  filterCount,
  applyFilter,
  applyClearFilter,
  searchKey,
  modifySearch,
  applySearch,
  applyClearSearch,
  resetFilter,
  exporting,
  exportReport,
}) => {
  return (
    <div className={styles?.container}>
      <div className={styles?.filter}>
        <Filter
          close={resetFilter}
          clear={applyClearFilter}
          submit={() => {
            applyFilter();
          }}
          filterCount={filterCount}
        >
          {/* <Field
            className={styles?.date}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            label={locale?.dateCaptured}
          >
            <DateRange
              value={{
                startDate: filterState.startDate,
                endDate: filterState.endDate,
              }}
              onChange={(_, { value }) => {
                const { startDate, endDate } = value;
                modifyFilter({ startDate, endDate });
              }}
            />
          </Field> */}
          <Field
            label={locale.fuelCategory}
            labelPosition={"left"}
            className={styles.fieldSelect}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name={"fuelCategory"}
              value={filterState.fuelCategory}
              getOptionLabel={(item) => item.label}
              getOptionSelected={(option, value) => option.label === value.label}
              hasAll
              options={[
                {
                  label: prettifyFuelCategory(FuelCategoryEnum.GASOLINE),
                  value: FuelCategoryEnum.GASOLINE,
                },
                {
                  label: prettifyFuelCategory(FuelCategoryEnum.DIESEL),
                  value: FuelCategoryEnum.DIESEL,
                },
                {
                  label: prettifyFuelCategory(FuelCategoryEnum.KEROSENE),
                  value: FuelCategoryEnum.KEROSENE,
                },
                {
                  label: prettifyFuelCategory(FuelCategoryEnum.LPG),
                  value: FuelCategoryEnum.LPG,
                },
              ]}
              onChange={(name, { value }) => {
                modifyFilter({ [name]: value ? value : "" });
              }}
            />
          </Field>
          <Field
            label={locale.benchMark}
            labelPosition={"left"}
            className={styles.fieldSelect}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name={"productCode"}
              value={filterState.productCode}
              getOptionLabel={(item) => item.label}
              getOptionSelected={(option, value) => option.label === value.label}
              hasAll
              options={[
                {
                  label: prettifyProductCode(ProductCodeEnum.DIESEL),
                  value: ProductCodeEnum.DIESEL,
                },
                {
                  label: prettifyProductCode(ProductCodeEnum.GAS91),
                  value: ProductCodeEnum.GAS91,
                },
                {
                  label: prettifyProductCode(ProductCodeEnum.GAS95),
                  value: ProductCodeEnum.GAS95,
                },
                {
                  label: prettifyProductCode(ProductCodeEnum.GAS97),
                  value: ProductCodeEnum.GAS97,
                },
                {
                  label: prettifyProductCode(ProductCodeEnum.GAS105),
                  value: ProductCodeEnum.GAS105,
                },
                {
                  label: prettifyProductCode(ProductCodeEnum.KEROSENE),
                  value: ProductCodeEnum.KEROSENE,
                },
                {
                  label: prettifyProductCode(ProductCodeEnum.LPG),
                  value: ProductCodeEnum.LPG,
                },
              ]}
              onChange={(name, { value }) => {
                modifyFilter({ [name]: value ? value : "" });
              }}
            />
          </Field>
        </Filter>
        <Field className={styles.search}>
          <SearchField
            restrict={false}
            searchWithSymbols
            placeholder={[locale.brand, locale.products].join(", ")}
            value={searchKey}
            onChange={(_, { value }) => {
              modifySearch(value);
            }}
            onEnter={applySearch}
            onClear={applyClearSearch}
          />
        </Field>
        <Field className={styles.downloadButton}>
          <Button
            startIcon={<span className="icon-download" />}
            onClick={exportReport}
            exportButton
            loading={exporting}
          >
            {locale.export}
          </Button>
        </Field>
      </div>
    </div>
  );
};

export default StationPricingFilter;
