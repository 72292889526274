import { Field, Modal, TextField, ActionButton } from "components/commons";
import { useApi, useForm, useModal } from "hooks";
import React, { useMemo } from "react";
import { initialState as formState } from "./user-access-form.state";
import styles from "./user-access.module.scss";
import { ConfirmModal } from "components/modals";
import locale from "localization";
import { useEffect } from "react";
import { handleRequest } from "utils";
import { ProfileType } from "enums";
import { createUser } from "apis/profile.api";

const UserAccessForm = ({ initialState, actionText, refreshPage, ...state }) => {
  const { close, show } = state;
  const confirmModal = useModal();
  const form = useMemo(() => {
    return formState(initialState);
  }, [initialState]);

  const {
    fields,
    modifyField,
    submitForm,
    getFormValues,
    applyFieldErrors,
    validateField,
    clearForm,
    isFormSubmittable,
  } = useForm({
    initialState: form,
  });

  useEffect(() => {
    if (state.active) {
      clearForm();
    }
    //eslint-disable-next-line
  }, [state.active]);

  const createUserRequest = useApi({
    api: createUser,
    handleOwnError: {
      badrequest: true,
    },
  });

  const loading = createUserRequest.loading;

  const submit = async () => {
    const params = getFormValues();

    handleRequest(
      async () => {
        await createUserRequest.request({ profileType: ProfileType.PT, email: params.email }, () =>
          submit()
        );

        close();
        refreshPage();
        confirmModal.show({
          title: locale.userSuccessfullyRegistered,
          content: (
            <locale.Populate
              text={locale.youHaveSuccessfullyRegistered}
              items={[<b>{params.email}</b>]}
            />
          ),
          primary: {
            onClick: () => {
              confirmModal.close();
            },
          },
          secondary: {
            text: locale.registerNewUser,
            onClick: () => {
              confirmModal.close();
              show({
                title: locale.addUser,
              });
            },
          },
        });
      },
      {
        P1014: () => {
          applyFieldErrors({
            email: locale.emailAddressExist,
          });
        },
      }
    );
  };

  return (
    <div>
      <ConfirmModal {...confirmModal} />
      <Modal close={loading ? false : close} {...state} className={styles.modalContent}>
        <div className={styles.formContent}>
          <div className={styles.content}>
            <Field {...fields.email}>
              <TextField {...fields.email} onChange={modifyField} onBlur={validateField} />
            </Field>
          </div>
        </div>
        <ActionButton
          right
          items={[
            {
              onClick: () => {
                state.close();
              },
              disabled: loading,
              text: locale.cancel,
            },
            {
              onClick: () => {
                submitForm(submit);
              },
              disabled: !isFormSubmittable,
              loading,
              text: locale.save,
            },
          ]}
        />
      </Modal>
    </div>
  );
};

export default UserAccessForm;
