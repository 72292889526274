import locale from "localization/en";

export const columns = [
  { key: "brandName", text: locale.brandName, width: "100px" },
  { key: "uploadId", text: `${locale.uploadId}/${locale.captureDate}`, width: "100px" },
  { key: "stationName", text: locale.stationName, width: "150px" },
  { key: "competitorOf", text: locale.competitorOf, width: "300px" },
  { key: "province", text: locale.province, width: "120px" },
  { key: "city", text: locale.city, width: "100px" },
  { key: "product", text: locale.product, width: "170px" },
  { key: "pumpPrice", text: locale.pumpPrice, width: "120px" },
  { key: "uploaderEmail", text: locale.uploaderEmail, width: "200px" },
  { key: "priceCapture", text: locale.priceCapture, width: "200px" },
];
