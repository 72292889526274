import React, { useState, useEffect, useMemo } from "react";

import styles from "./dropdown-menu.module.scss";
import classNames from "classnames";
import Image from "../image/image";
import { NavLink } from "react-router-dom";
import { keypress } from "utils";

const DropdownMenu = ({ morIconClass = null, menuItems }) => {
  const [active, setActive] = useState(false);

  const url = window.location.href.split("/")[3];

  let currentUrl = useMemo(() => {
    return url;
  }, [url]);

  const Items = ({ menuItems }) => {
    return menuItems.map((item) => {
      const itemAsActive = item.link.split("/").pop() === currentUrl;
      return (
        <NavLink
          key={item.label}
          to={item.link}
          exact={true}
          className={classNames(styles.listItem, {
            [`${styles.active}`]: itemAsActive,
          })}
          activeClassName={classNames(styles.active)}
          onClick={() => {
            item.onClick();
            setActive(false);
          }}
        >
          <span className={styles.item}>
            {item.icon && <Image className={styles.icon} src={item.icon} />}
            <span className={styles.listText}>{item.label}</span>
          </span>
        </NavLink>
      );
    });
  };

  useEffect(() => {
    keypress("Escape", () => {
      if (active) setActive(false);
    });
  }, [active]);

  return (
    <div className={styles.main}>
      {active && (
        <div
          className={styles.overlay}
          onClick={() => {
            setActive(false);
          }}
        ></div>
      )}

      <span
        onClick={() => {
          setActive(() => !active);
        }}
        className={morIconClass || styles.moreIcon}
      >
        <span style={{ cursor: "pointer" }} className={`icon icon-grid`} />
      </span>

      <div
        className={classNames(styles.filterContent, {
          [`${styles.active}`]: active,
          [`${styles.inActive}`]: !active,
        })}
      >
        <Items menuItems={menuItems} />
      </div>
    </div>
  );
};

export default DropdownMenu;
