import locale from "localization";
import Validation from "utils/validation.utils";

export const initialState = (state = {}) => {
  const {
    stationCode = {
      value: "",
    },
    stationLocation = {
      value: "",
    },
    validCoordinates = {
      value: false,
    },
  } = state;

  return {
    stationCode: {
      name: "stationCode",
      required: true,
      validations: [Validation.required(), Validation.maxlength(4), Validation.numeric()],
      label: locale.stationCode,
      placeholder: locale.stationCodeZero,
      horizontal: true,
      ...stationCode,
    },
    validCoordinates: {
      name: "validCoordinates",
      ...validCoordinates,
    },
    stationLocation: {
      name: "stationLocation",
      required: true,
      validations: [Validation.required({ noMessage: true }), Validation.maxlength(150)],
      placeholder: "Longitude / Latitude",
      ...stationLocation,
    },
  };
};
