export const locale = {
  otc: "OTC",
  gcash: "GCash",
  billease: "Billease",
  cybersource: "Cybersource",
  brankas: "Brankas",
  action: "Action",
  paymentMethodAndPurchasedDate: "Payment Method / Purchased Date",
  shortDescription: "Short description",
  longDescription: "Long description",
  viewRedemptions: "View redemptions",
  save: "Save",
  editDetails: "Edit details",
  deactivate: "Deactivate",
  activate: "Activate",
  vehicle: "Vehicle",
  drumContainer: "Drum/Container",
  manageFleetVehicles: "Manage fleet vehicles",
  manageYourVehicle: "Manage your vehicles and keep their information up to date.",
  addVehicles: "Add Vehicles",
  addVehicle: "Add Vehicle",
  add: "Add",
  sorryNoResultFound: "Sorry, no results found",
  weCouldNotFindAMatchForKey: "We could not find a match for “{0}“.",
  pleaseTryADifferentOne: "Please try a different one.",
  vehicleSuccessfullyRegistered: "Vehicle successfully registered!",
  vehicleInformationSuccessfullyEdited: "Vehicle information successfully edited!",
  youHaveSuccessfullyRegisteredPlateNumber:
    "You have successfully registered {0} to the vehicles’ list.",
  youHaveEditedVehicleDetails: "You have successfully changed the information of {0}.",
  registerNewVehicle: "Register New Vehicle",
  vehicleIdAlreadyExists: "This Vehicle ID already exists.",
  cancel: "Cancel",
  manageFleetDrivers: "Manage fleet drivers",
  manageYourDrivers: "Manage your drivers and keep their information up to date.",
  addDriver: "Add Driver",
  driverSuccessRegistered: "Driver successfully registered!",
  driverSuccessEdited: "Driver information successfully edited!",
  successfullyRegisteredDriverName:
    "You have successfully registered {0} {1} to the drivers' list.",
  successfullyChangeInfoOfDriverName: "You have successfully changed the information of {0} {1}",
  registerNewDriver: "Register New Driver",
  driverLicenseIdExists: "This Driver ID already exists.",
  enterFirstNameHere: "Enter first name here",
  enterLastNameHere: "Enter last name here",
  pleaseEnterAValidMobileNumber: "Please enter a valid mobile number.",
  pleaseEnterAValidTelephoneNumber: "Please enter a valid telephone number.",
  plateNumber: "Plate Number",
  vehicleModelType: "Vehicle Model Type",
  remarksOptions: "Remarks (optional)",
  remarks: "Remarks",
  enterRemarksHere: "Enter remarks here",
  plateNumberPlaceholder: "XXXXXX (don't include spaces or dash)",
  anyDriverAnyVehicle: "Any Driver, Any Vehicle",
  youAreAboutToDeactivateVehicle:
    "You are about to deactivate vehicle {0}. This will temporarily prevent vehicle {1} from doing any transaction. Do you want to proceed?",
  youAreAboutToReactivateVehicle:
    "You are about to reactivate vehicle {0}. This will enable vehicle {1} to do transactions again. Do you want to proceed?",
  askDeactivateVehicle: "Deactivate vehicle?",
  askReactivateVehicle: "Reactivate vehicle?",
  yesDeactivateIt: "Yes, Deactivate it",
  yesDeleteIt: "Yes, Delete it",
  yesReactivateIt: "Yes, Reactivate it",
  youAreAboutDeactivateDriver:
    "You are about to deactivate {0}’s account. This will temporarily prevent {1} from doing any transaction. Do you want to proceed?",
  youAreAboutReactivateDriver:
    "You are about to reactivate {0}’s account. This will enable {1} to do transactions again. Do you want to proceed?",
  askDeactivateDriver: "Deactivate driver?",
  askReactivateDriver: "Reactivate driver?",
  firstName: "First Name",
  lastName: "Last Name",
  pleaseEnterAValidLicensedId: "Please enter a valid driver license ID.",
  mobileNumber: "Mobile Number",
  driverId: "Driver ID",
  all: "All",
  clearAll: "Clear All",
  applyFilters: "Apply Filters",
  nameMobileNumberDriverId: "Name, Mobile Number, Driver ID",
  name: "Name",
  status: "Status",
  referenceStatonSelected: "Reference Station/ Selected Station",
  referenceStations: "Reference Stations",
  referenceStation: "Reference Station",
  email: "Email",
  filters: "Filters",
  statusCashInDate: "Status / Cash In Date",
  model: "Model",
  emailAddress: "Email Address",
  password: "Password",
  rememberMe: "Remember Me",
  logIn: "Log In",
  alreadyExists: "{0} already exists.",
  theEnteredEmailPassIncorrect: "The entered email or password is incorrect",
  fleetPortal: "Fleet Portal",
  pleaseEnterAValidEmailAddress: "Please enter a valid email address.",
  cashInHistory: "Cash in History",
  cashInHistoryCapitalize: "Cash In History",
  referenceId: "Reference ID",
  referenceNumber: "Reference Number",
  referenceIdAndNumber: "Reference ID, Reference Number",
  referenceIdAndBusinessName: "Reference ID/Number, Business Name",
  buyFuelIdAndBusinessName: "Buy Fuel ID, Business Name",
  cashInIdAndBusinessName: "Cash In ID/Number, Business Name",
  redemptionIdAndBusinessName: "Redemption ID, Business Name",
  redemptionIdAndBusinessNameFuelCodeORNumber:
    "Redemption ID, Station Business Name, Fuel Code, OR Number, Business Name",
  cashInAmount: "Cash In Amount",
  cashInFeeLocq: "Cash In Fee (LOCQ)",
  cashInFeeCustomer: "Cash In Fee (Customer)",
  amount: "Amount",
  createdDate: "Created Date",
  fullfilledDate: "Fulfilled Date",
  cashInChannel: "Cash-In Channel",
  active: "Active",
  smallCaseActive: "active",
  inactive: "Inactive",
  deactivated: "Deactivated",
  completed: "Completed",
  pending: "Pending",
  failed: "Failed",
  expired: "Expired",
  gasName: "Gas {0}",
  diesel: "Diesel",
  gas91: "Gas 91",
  gas95: "Gas 95",
  gas97: "Gas 97",
  manualPaymaya: "Manual - Paymaya",
  manualBankDeposit: "Manual - Bank Deposit",
  purchasedDate: "Purchased Date",
  dateOnboarded: "Date Onboarded",
  product: "Product",
  creditPrice: "Credit Price (₱/L)",
  supplierWpp: "Supplier WPP (₱/L)",
  volume: "Volume",
  volumeCreditPrice: "Volume/Credit Price(₱/L)",
  totalCreditAmount: "Total Credit Amount",
  dispensedTo: "Dispensed to",
  buyFuelHistory: "Buy fuel history",
  buyFuel: "Buy fuel",
  buyFuelCapitalize: "Buy Fuel",
  selectProductType: "Select Product Type",
  pleaseSelectProductYouWish: "Please select product you wish to purchase.",
  cashFlow: "Cash Flow",
  cashIn: "Cash In",
  ofCharacters: "{0} / {1} Characters",
  viewTrackCashActivities: "View and track station prices in your area",
  viewTrackstationAccounts: "View and track station accounts.",
  view: "View",
  fuel: "Fuel",
  generalFuelCodes: "Generate Fuel Codes",
  myFuelCodes: "My Fuel Codes",
  fleetActivity: "Fleet Activity",
  redemptions: "Redemptions",
  drivers: "Drivers",
  vehicles: "Vehicles",
  onlyAlphaNumericAllowed: "Only Alphanumeric characters are allowed.",
  onlyAlphaNumericAllowedAndDash:
    "Only Alphanumeric characters and the dash symbol ( - ) are allowed.",
  onlyAlphaNumericAllowedAndSpecial:
    "Only Alphanumeric characters and the following special symbol ( - , . , spaces ) are allowed.",
  mobileNumberIsInvalid: "This mobile number is invalid.",
  driverLicenseIdIsInvalid: "This driver license ID is invalid.",
  date: "Date",
  type: "Type",
  cashInDate: "Cash In Date",
  to: "to",
  gotIt: "Got It",
  onlyNumericAreAllowed: "Only numeric characters are allowed.",
  ohSnap: "Oh snap!",
  somethingWentWrongPleaseTryAgain: "Something went wrong. Please try again.",
  tryAgain: "Try Again",
  generateFuelCode: "Generate fuel code",
  balance: "Balance",
  productType: "Product Type",
  volumeRedeemLimit: "Volume Redeem Limit",
  eligiblePeriod: "Eligible Period",
  eligibleDrivers: "Eligible Drivers",
  eligibleVehicles: "Eligible Vehicles",
  continue: "Continue",
  searchOrSelect: "Search or select",
  kindlyReviewDetails: "Kindly review the below details before generating the fuel code.",
  generateFuelCodeCapitalize: "Generate Fuel Code",
  successfullyGeneratedFuelCode: "You Successfully Generated Fuel Code!",
  shareAndStartUsingThisCode: "Share and start using this code to redeem fuels at the stations.",
  goToMyFuelCodes: "Go to My Fuel Codes",
  goToMops: "Go to MOPS Price",
  generateAnotherCode: "Generate Another Code",
  maximumAllowedRedeemLiters: "Maximum allowed redeem limit is {0} liters.",
  minimumAllowedLitre: "Minimum allowed redeem limit is {0} {1}.",
  priceHistory: "Price History",
  effectiveDate: "Effective Date",
  fuelPrice: "Fuel Price",
  priceMovement: "Price Movement",
  discount: "Discount",
  creditPriceHistory: "Credit Price",
  myFuelCodesTitle: "My fuel codes",
  createFuelCodes: "Create fuel codes and keep track of their expiry dates.",
  dateGenerated: "Date Generated",
  fuelCode: "Fuel Code",
  fuelCodes: "Fuel Codes",
  codeStartDate: "Code Start Date",
  codeEndDate: "Code End Date",
  volumeLimit: "Volume Limit",
  volumeRedeemed: "Volume Redeemed",
  useLimit: "Use Limit",
  useCount: "Use Count",
  eligibleDriversAndVehicles: "Eligible Drivers and vehicles",
  scheduled: "Scheduled",
  actions: "Actions",
  stepOneSelectFuel: "Step 1: Select Fuel",
  viewPriceHistory: "View Price History",
  liters: "Liters",
  liter: "Liter",
  peso: "Peso",
  stepTwoEnterAmount: "Step 2: Enter Amount",
  buyFuelHistoryCapitalize: "Buy Fuel History",
  totalLitersIsAmount: "Total liters is {0}.",
  totalAmountIs: "Total amount is {0}.",
  minimumOrderIs1Liter: "Minimum order is 1 liter.",
  askConfirmWithPayment: "Proceed with payment?",
  askConfirmWithChanges: "Proceed with changes?",
  nLiter: "{0} Liter",
  nLiters: "{0} Liters",
  ofText: "of {0}",
  isAboutToBePurchased: "is about to be purchased.",
  kindlyReviewTheDetails: "Kindly review the details before proceeding.",
  kindlyReviewTheBelowInfo: "Kindly review the below information before saving your changes.",
  productPrice: "Product Price",
  total: "Total",
  totalCaps: "TOTAL",
  pay: "Pay",
  success: "Success",
  exclamatedSuccess: "Success!",
  paymentConfirmed: "Your payment has been confirmed.",
  yourChangesSaved: "Your changes have been saved.",
  goToBuyFuelHistory: "Go to Buy Fuel History",
  askDeactivateFuelCode: "Deactivate Fuel Code?",
  youAreAboutDeactivateFuelCode:
    "You are about to deactivate fuel code {0}. This will disable the said fuel code forever and will not be usable anymore. Do you want to proceed?",
  buyFuelFailed: "Buy Fuel transaction failed",
  errorProcessingPayment: "There was an error processing your payment. Please try again.",
  buyFuelAgain: "Buy Fuel Again",
  oops: "Oops! Something went wrong",
  pleaseRefreshYourBrowser: "Please refresh your browser",
  backToHomePage: "Back to Home Page",
  okayGotIt: "Okay, got it!",
  viewTrackRedemptions: "View, track redemption activities of your fleet.",
  viewAllTrackRedemptions: "View and track redemption activities.",
  redemptionDate: "Redemption Date",
  redemptionStation: "Redemption Station",
  redemptionStationAndDate: "Redemption Station/ Redemption Date",
  dispensedToPlateNumber: "Dispensed to/ Plate Number",
  redemptionStations: "Redemption Stations",
  aveCreditPrice: "Ave. Credit Price",
  driverIdPlateNumberFuelCode: "Driver ID, Plate No., Fuel Code",
  redemptionIdBusinessName: "Redemption ID, Business Name",
  redemptionId: "Redemption ID",
  redemptionItemId: "Redemption Item ID",
  redemptionItemIdAndId: "Redemption Item ID/Redemption ID",
  literVolume: "Liter Volume",
  welcome: "Welcome,",
  yourFleetConsumptionForTheMonth: "Your Fleet Consumption for the Month",
  activateAccount: "Activate Account",
  basicInformation: "Basic Information",
  confirmPassword: "Confirm Password",
  setPassword: "Set Password",
  use8CharWithMix: "Use 8 or more characters with a mix of letters, numbers and symbols",
  passwordsDoNotMatch: "Passwords do not match",
  passwordDoesNotMeetRequirements: "Password does not meet requirements",
  goToLogin: "Go to Login",
  pleaseSelectProductType: "Please select product type",
  totalBalance: "Total Balance: {0}",
  insufficientWalletBalance: "Insufficient wallet balance",
  locqPortal: "LOCQ Portal",
  fleetAccounts: "Fleet Accounts",
  stationAccountList: "Station Account List",
  accounts: "Accounts",
  pricing: "Pricing",
  fleetAccountList: "Fleet Account List",
  mopsPrice: "MOPS Price",
  gasoil: "Gasoil",
  mogas: "Mogas",
  editMopsPrice: "Edit MOPS Price",
  mopsId: "MOPS-ID",
  mopsSpaceId: "MOPS ID",
  price: "Price",
  fleetPrices: "Fleet Prices",
  priceMovements: "Price Movements",
  locq: "LOCQ",
  customers: "Customers",
  viewDetailsOfPriceLocqAppUsers: "View details of PriceLOCQ App users.",
  customerList: "Customer List",
  viewTrackPurchases: "View and track purchases.",
  viewTrackFleetAccounts: "View and track fleet accounts.",
  viewMonitorFleetPrice: "View and monitor price updates.",
  businessNameId: "Business Name/ID",
  stationBusinessName: "Station Business Name",
  nameAndMobileNumber: "Name/Mobile Number",
  natureOfBusiness: "Nature of Business",
  typesOfBusiness: "Type of Business",
  pesoBalance: "Peso Balance​",
  literBalance: "Liter Balance​",
  statusDate: "Status/Date​",
  businessName: "Business Name",
  businessNameNatureOfBusiness: "Business Name, Nature of Business",
  fleetAccountDetails: "Fleet Account Details",
  addFleetAccount: "Add Fleet Account",
  editFleetAccount: "Edit Fleet Account",
  viewDetails: "View Details",
  reactivate: "Reactivate",
  buyFuelId: "Buy Fuel ID",
  cashInId: "Cash In ID",
  cashInIdNumber: "Cash In ID/Number",
  businessInformation: "Business Information",
  businessAddress: "Business Address",
  province: "Province",
  city: "City",
  emailForFleetAccount: "Email Address (for fleet portal access)​",
  emailForFleetAccountHelperText: "Add email addresses (up to 5) separated by a comma or space.",
  uniqueFuelCodePrefix: "Unique Fuel Code Prefix",
  acquirerName: "Acquirer Name",
  acquirerEmail: "Acquirer Email",
  acquirerNameSample: "e.g AB4DE",
  contactInformation: "Contact Information",
  businessTelephoneNumber: "Telephone Number",
  businessTelephoneNumberSample: "(02) XXXX - XXXX",
  businessRepresentativeName: "Business Representative​",
  representativeMobileNumber: "Representative’s Mobile Number​​",
  existingVolume: "Existing Volume​",
  representativeMobileNumberSample: "09XXXXXXXXX​​",
  representativeEmail: "Representative’s Email Address​",
  basePriceUponOnboarding: "Base Price Upon Onboarding​",
  baseDieselPrice: "Base Price Upon Onboarding​",
  agreedDiscounts: "Agreed Discounts",
  estimateMonthlyVolume: "Estimate Monthly Volume​",
  saveDetailsAndSendEmail: "Save details and send email?",
  saveDetails: "Save Details",
  areYouSure: "Are you sure?",
  saveAndSendEmail: "Save and Send Email​",
  saveAndSendEmailContentMessage:
    "Upon clicking “Save and Send Email”, an account activation instruction will be sent to the email addresses you’ve provided. Do you want to proceed?",
  accountActivationSuccessMessage:
    "An account activation instruction has been sent to the email addresses you’ve provided.",
  goToListOfFleet: "Go to List of Fleet",
  addAnotherAccount: "Add Another Account",
  onBoardedWithDate: "Date Onboarded {0}",
  edit: "Edit",
  reviewChangesBelow: "Please review the below changes before you continue.",
  detailHaveBeenSuccessfullyUpdated: "Details have been successfully updated.",
  deactivateFleetAccount: "Deactivate fleet account?",
  reactivateFleetAccount: "Reactivate fleet account?",
  deactivateFleetAccountContent:
    "You are about to deactivate {0}. This will temporarily prevent {0} from doing any transaction. Do you want to proceed?",
  reactivateFleetAccountContent:
    "You are about to reactivate {0}. This will enable {0}​ to do transactions again. Do you want to proceed?",
  accountUpdateSaveWithEmail:
    "An account activation instruction will be sent to the new email addresses. Please review the below changes before you continue.",
  forResending: "For resending",
  newUser: "New user",
  removedUser: "Removed user",
  referenceStaton: "Reference Station",
  referenceStationType: "Reference Station Type",
  selectedStation: "Selected Station",
  uniqueReferalCode: "Unique Referral Code",
  dateRegistered: "Date Registered",
  dateOfLastUpdate: "Date of Last Update",
  referalCode: "Referral Code",
  pricelocqConsumer: "PriceLOCQ for Consumer",
  pricelocqBusiness: "PriceLOCQ for Business",
  pricelocq: "PriceLOCQ",
  depot: "Depot",
  pricingInformation: "Pricing Information ",
  sdum: "SDUM",
  freight: "Freight",
  redemption: "Redemption",
  youAreAboutToDeactivateAccount:
    "You are about to deactivate {0} account. This will temporarily prevent Gener Enriquez from doing any transaction. Do you want to proceed?",
  youAreAboutToCancelCampaign: "You are about to cancel {0}. Do you want to proceed?",
  youAreAboutToReactivateCampaign: "You are about to reactivate {0}. Do you want to proceed?",
  deactivateAccount: "Deactivate account",
  reactivateAccount: "Reactivate account",
  cancelCampaign: "Cancel campaign",
  reactivateCampaign: "Reactivate campaign",
  yesCancelIt: "Yes, Cancel It",
  campaigns: "Campaigns",
  voucherCampaigns: "Voucher Campaigns",
  voucherCampaignDetails: "Voucher Campaign Details",
  campaignsAndPrograms: "Campaigns and Programs",
  discountCampaigns: "Discount Campaigns",
  voucherCampaignId: "Voucher Campaign ID",
  prefix: "Prefix",
  value: "Value",
  voucher: "Voucher",
  voucherPrefix: "Voucher prefix",
  voucherQuantity: "Voucher quantity",
  voucherType: "Voucher type",
  voucherValue: "Voucher value",
  campaignImage: "Campaign image",
  voucherCurrency: "Voucher currency",
  vouchers: "Vouchers",
  currency: "Currency",
  viewAndCreateVoucherCampaigns: "View and create voucher campaigns.",
  viewAndCreateDiscountCampaigns: "View and create discount campaigns.",
  actualRedemptions: "Actual Redemptions",
  quantityMaxRedemptions: "Quantity Max Redemptions",
  startDate: "Start Date",
  endDate: "End Date",
  dateCreated: "Date Created",
  createVoucherCampaign: "Create Voucher Campaign",
  createDiscountCampaign: "Create Discount Campaign",
  editDiscountCampaign: "Edit Discount Campaign",
  editVoucherCampaign: "Edit Voucher Campaign",
  campaignVoucher: "Campaign Voucher",
  newCustomerVouchers: "New Customer Vouchers",
  upcoming: "Upcoming",
  ongoing: "Ongoing",
  ended: "Ended",
  cancelled: "Cancelled",
  points: "Points",
  generic: "Generic",
  unique: "Unique",
  uniqueFuelCodePrefixAlreadyExist: "Unique Fuel Code Prefix name already exist",
  import: "Import",
  export: "Export",
  programs: "Programs",
  discountCampaignId: "Discount Campaign ID",
  stations: "Stations",
  discounts: "Discounts",
  keepTrackFuelCodesExpiryDates: "Keep track of fuel codes expiry dates.",
  viewAndTrackMopsPrice: "View and track MOPS price.",
  driverSpecific: "Driver Specific",
  vehicleSpecific: "Vehicle Specific",
  driverVehicleSpecific: "Driver and Vehicle Specific",
  fuelCodeType: "Fuel Code Type",
  assignedDriver: "Assigned Driver",
  assignedVehicle: "Assigned Vehicle",
  none: "None",
  availableProduct: "Available Product",
  youAreAboutDeactivateAccount:
    "You are about to deactivate {0} account. This will temporarily prevent {1} from doing any transaction. Do you want to proceed?",
  youAreAboutReactivateAccount:
    "You are about to activate {0} account. This will allow {1} to perform transactions in the app. Do you want to proceed?",
  weCouldNotFindAMatchForSearchCriteria: "We could not find a match for your search criteria",
  campaignRules: "Campaign Rules",
  campaignName: "Campaign name",
  campaignDuration: "Campaign duration",
  specifictReferenceStation: "Specific reference station",
  newRegistrants: "New registrants",
  typeOfUser: "Type of user",
  create: "Create",
  stationName: "Station Name",
  stationCode: "Station Code",
  stationType: "Station Type",
  stationGroup: "Station Group",
  products: "Products",
  discountFinal: "Discount Final",
  locqFeeFinal: "LOCQ Fee Final",
  orumFinal: "ORUM Final",
  orNumber: "OR Number",
  yes: "Yes",
  no: "No",
  createCampaign: "Create campaign?",
  createCampaignError: "Create Campaign Error",
  yesCreateCampaign: "Yes, Create Campaign",
  areYouSureProceedCreatingCampaign:
    "Are you sure you want to proceed with creating this campaign?",
  areYouSureSaveAllChangesCampaign:
    "Are you sure you want to save all changes made to this campaign?",
  discountCampaignDetails: "Discount Campaign Details",
  continueEditing: "Continue editing",
  saveChanges: "Save Changes",
  saveChangesQuestion: "Save changes?",
  saveChangesError: "Save Changes Error",
  stationNameStationCode: "Station Name/Station Code",
  accountStatus: "Account Status",
  accountUserEmails: "Account User Emails",
  rsmRtmEmails: "RSM/RTM Emails",
  testFuelCode: "Test Fuel Code",
  testFuelCodeStatus: "Test Fuel Code Status",
  dateRedeemed: "Date Redeemed",
  redeemed: "Redeemed",
  locqRebate: "LOCQ Rebate",
  card: "Card",
  onlineBanking: "Online Banking",
  oneTimeUse: "One-time use",
  accountType: "Account Type",
  accountTypes: "Account Types",
  addNewAccountType: "Add New Account Type",
  viewCreateAccountTypes: "View and create account types.",
  accountTypeName: "Account Type Name",
  default: "Default",
  pesoWalletSize: "Peso Wallet Size",
  fuelTankSize: "Fuel Tank Size",
  dailyCashInLimitMonthlyCashInLimit: "Daily Cash In Limit / Monthly Cash In Limit",
  dailyBuyFuelLimitMonthlyBuyFuelLimit: "Daily Buy Fuel Limit / Monthly Buy Fuel Limit",
  dailyRedemptionLiterLimit: "Daily Redemption / Liter Limit",
  perStation: "Per station",
  universal: "Universal",
  editAccountType: "Edit account type",
  deleteAccountType: "Delete account type",
  deleteAccountTypeQuestion: "Delete account type?",
  deleteAccountTypeContent:
    "You are about to delete {0}. All users under this account type will be converted to a Basic Account. Should there be users with balances higher than the balance limits of a Basic Account, they will not be able to cash-in or buy fuel until their balances fall under the limit.",
  createAccountType: "Create Account Type",
  walletLimit: "Wallet Limit",
  fuelLimit: "Fuel Limit",
  cashInLimit: "Cash In Limit",
  totalCashInLimit: "Total Cash In Limit",
  willOverrideViaCardLimit: "(Will override via card limit)",
  transactionsViaCard: "Transactions via card",
  per: "per",
  perDay: "per day",
  perMonth: "per month",
  buyFuelLimit: "Buy Fuel Limit",
  totalBuyFuelLimit: "Total buy fuel limit",
  buyFuelViaCard: "Buy fuel via card",
  perAccountScanToPayLimit: "Per Account (device) Scan to Pay Limit",
  redemptionLimit: "Redemption limit",
  literLimit: "Liter limit",
  createAccountTypeQuestion: "Create account type?",
  areYouSureCreatingAccountType:
    "Are you sure you want to proceed with creating this account type?",
  areYouSureSavingAccountType:
    "Are you sure you want to save all changes made to this account type?",
  yesCreateAccountType: "Yes, Create Account Type",
  accountTypeDetails: "Account Type Details",
  selectAccountType: "Select Account Type",
  askChangeAccountType: "Change account type?",
  youAreChangingTheAccountTypeOf:
    "You are changing the account type of {0} to {1}. Do you want to proceed?",
  yesProceed: "Yes, proceed",
  logout: "Logout",
  allStations: "All Stations",
  allBrands: "All Brands",
  selectAll: "Select All",
  select: "Select",
  allRedemptionStations: "All Redemption Stations",
  attributes: "Attributes",
  userAttributes: "User Attributes",
  viewDetailsCustomerAttributes: "View details of customer attributes.",
  addAttribute: "Add Attribute",
  editAttribute: "Edit Attribute",
  editAttributes: "Edit Attributes",
  delete: "Delete",
  addUserAttribute: "Add User Attribute",
  editUserAttribute: "Edit User Attribute",
  cohorts: "Cohorts",
  userAttributesName: "User Attributes Name",
  cohortAlreadyExists: "Cohort already exists. Please check out the cohort list.",
  noCohortsYet: "You don’t have cohorts yet.",
  createAttributeQuestion: "Create attribute?",
  areYouSureCreatingAttribute: "Are you sure you want to proceed with creating this attribute?",
  areYouSureSavingChangesAttribute:
    "Are you sure you want to save all changes made to this Attribute?",
  yesCreateAttribute: "Yes, Create Attribute",
  userAttributeDetails: "User Attribute Details",
  deleteAttributeQuestion: "Delete attribute?",
  youAreAboutDeleteAttribute: "You are about to delete {0} attribute. Do you want to proceed?",
  viewPurchasedVouchers: "View Purchased Vouchers",
  viewReferrals: "View Referrals",
  viewBuyFuel: "View Buy Fuel",
  viewCashins: "View Cash-ins",
  addCustomAttribute: "Add Custom Attribute",
  wouldYouLikeToSaveTheseAttributeChanges: "Would you like to save these attribute changes?",
  confirm: "Confirm",
  targetUsers: "Target Users",
  dragAndDropFilesToUpload: "Drag and drop files to upload, or click to select file",
  importSuccessfulWithErrors: "Import successful! (but with Errors)",
  importSuccessfulWithErrorsContent:
    "File uploaded success, but some of your entries were not applied.",
  importSuccessful: "Import successful!",
  retry: "Retry",
  attributeAlreadyExists: "Attribute already exists. Please try a different attribute name.",
  voucherPrefixAlreadyExists:
    "Voucher prefix already exist. Please try a different voucher prefix.",
  voucherCampaignNameAlreadyExists:
    "Voucher campaign name already exist. Please try a different voucher campaign name.",
  error: "Error",
  invalidCsvFileTypeFormat: "Invalid CSV file type format",
  attributeDoesNotExist: "Attribute does not exist: ",
  customerDoesNotExist: "Customer does not exist (row): ",
  cohortDoesNotExist: "Cohort does not exist: ",
  usersUpdated: "Users updated: ",
  usersWithoutThisAttribute: "Users without this attribute",
  selectStations: "Select Stations",
  numberSelected: "{0} Selected",
  stationsSelected: "{0} Station selected",
  numberStations: "{0} Stations",
  numberAttributeName: "{0} {1}",
  voucherCode: "Voucher Code",
  valueCurrency: "Value/Currency",
  claimedBy: "Claimed By",
  startDateEndDate: "Start Date/End Date",
  dateClaimedDateUsed: "Date Claimed/Date Used",
  availableInApp: "Available in App",
  used: "Used",
  unclaimed: "Unclaimed",
  uniqueVouchers: "Unique Vouchers",
  viewAllUniqueVouchers: "View all unique vouchers.",
  selectStatus: "Select Status",
  selectCurrency: "Select Currency",
  void: "Void",
  dateClaimed: "Date Claimed",
  oilco: "Oilco",
  dealer: "Dealer",
  chargedTo: "Charged to",
  campaignAlreadyExist: "Campaign name already exists.",
  loading: "Loading...",
  reactivatingCampaignMessage: "You are about to reactivate this campaign. Do you want to proceed?",
  voided: "Voided",
  voidTransaction: "Void Transaction",
  voidingThisTransaction:
    "Voiding this transaction will return {0} to the account’s liter balance and will be excluded in the next settlement cycle. Input the reason for voiding below to proceed.",
  voidingThisTransactionSettlementProcessing:
    "Voiding this transaction will return {0} to the account’s liter balance. This transaction is already in the process of completing settlement. It will be deducted in the next settlement cycle. Input the reason for voiding below to proceed",
  voidingThisTransactionSettlementProcessed:
    "Voiding this transaction will return {0} to the account’s liter balance. This transaction has already been processed for settlement. It will be deducted in the next settlement cycle. Input the reason for voiding below to proceed.",
  transactionVoided: "Transaction successfully voided",
  thisTransactionHasBeenVoided:
    "This transaction has been voided and {0} has been successfully returned to {1}",
  overrideDetails: "Override Details",
  overrideFuelCodeDetails: "Override Fuel Code Details - {0}",
  minMaxDuration: "Duration range must be {0}.",
  createManualCashIn: "Create Manual Cash In",
  businessId: "Business ID",
  youAreAboutToCredit:
    "You are about to credit ₱{0} to the peso balance of {1}. Do you want to proceed?",
  fuelCodeDeactivated: "Fuel code deactivated!",
  fuelCodeReactivated: "Fuel code reactivated!",
  codeNoLongerBeUsed: "{0} can no longer be used to redeem fuels at the station.",
  codeCanNowBeUsed: "{0} can now be used to redeem fuels at the station.",
  youAreAboutReactivateFuelCode:
    "You are about to reactivate fuel code {0}. This will enable the said fuel code forever and will be usable again. Do you want to proceed?",
  manualCashInCreated: "Manual Cash In Created!",
  youHaveSuccessfullyCredited: "You have successfully credited ₱{0} to the peso balance of {1}.",
  businessIdNotFound: "Business ID not found",
  searchOrSelectCashInChannel: "Search or select cash in channel",
  availableProducts: "Available Products",
  productConversions: "Product Conversions",
  productConversionId: "Product Conversion ID",
  conversionDate: "Conversion Date",
  platform: "Platform",
  priceLocqAccount: "PriceLOCQ Account",
  pricelocqAccountName: "PriceLOCQ Account / Account Name",
  convertFrom: "Convert from",
  convertTo: "Convert To",
  durationDays: "Duration",
  durationInDays: "Duration In Days",
  originalBuyFuelId: "Original Buy Fuel ID",
  newBuyFuelId: "New Buy Fuel ID",
  originalReferenceStation: "Original Reference Station",
  originalReferenceDate: "Original Reference Date",
  newReferenceStation: "New Reference Station",
  newReferenceDate: "New Reference Date",
  viewAndTrackProductConversions: "View and track product conversions.",
  addNewProductConversion: "Add New Product Conversion",
  accountName: "Account Name",
  accountDetails: "Account Details",
  plcMobileNumber: "PLC Mobile Number",
  plbBusinessId: "PLB Business ID",
  conversionDetails: "Conversion Details",
  beforeConversion: "Before Conversion",
  afterConversion: "After Conversion",
  aNewIdWillBeCreated: "A new ID will be created.",
  referenceDate: "Reference Date",
  setDate: "Set Date",
  bnpl: "BNPL",
  newCustomerVoucher: "New Customer Voucher",
  referral: "Referral",
  ewallet: "E-Wallet",
  priceBuildup: "Price Build-up",
  viewSationPricesAndMargin: "View station prices and margin breakdown.",
  searchStationNameSationCode: "Search Station Name, Station Code",
  syncWithPricingTool: "Sync with Pricing Tool",
  stationNameStationType: "Station Name/Station Type",
  supplierWppLabel: "Supplier WPP",
  sdumf: "SDUMF",
  orum: "ORUM",
  usersFuelBalance: "User’s {0} Balance",
  proceedWithProductConversion: "Proceed with product conversion?",
  youAreAboutToConvertUserDoYouWantToProceed:
    "You are about to convert {0} {1} ({2}) to {3} ({4}). Do you want to proceed?",
  confirmProductConversion: "Confirm Product Conversion",
  productConverted: "Product Converted",
  youHaveSuccessfullyConvertedUserProduct:
    "You have successfully converted {0} {1} ({2}) to {3} ({4}).",
  viewAndTrackRedemptionOfNewCustomerVouchers:
    "View and track redemption of new customer vouchers.",
  available: "Available",
  claimed: "Claimed",
  dateRedeemedRange: "Date Redeemed Range",
  any: "Any",
  overrideFuelCode: "Override Fuel Code",
  proceed: "Proceed",
  fuelcodeUpdated: "Fuel code updated!",
  currentRedeemedVolumeIsN: "Current redeemed volume is {0}",
  codeHasBeenUsed: "Code has beed used {0}",
  times: "times",
  time: "time",
  overrideSuccessful: "Override successful!",
  youHaveSuccessfullyOverridenFuelCode:
    "You have successfully overriden the information the information of {0}.",
  sendFuelCodeAsSms: "Send Fuel Code as SMS to assigned driver",
  approxPesoEquivalent: "Approx. Peso Equivalent: {0}",
  limitCodeOneTime: "Limit code to one-time use",
  canOnlyRedeemAt: "The generated fuel code can only be redeemed at these {0}.",
  editRemarks: "Edit Remarks",
  reason: "Reason",
  noFuelBalanceAvailable: "No balance available for {0}.",
  cashinMethod: "Cash In Method",
  sevenEleven: "7-Eleven",
  BDO: "BDO",
  unionBank: "Union Bank",
  seaoil: "SEAOIL",
  expressPay: "ExpressPay",
  ecpay: "ECPay",
  hlhuillier: "H Lhuillier",
  rDPawnshop: "RD Pawnshop",
  trueMoney: "TrueMoney",
  kycSubmission: "KYC Submission",
  kycSubmissions: "KYC Submissions",
  viewAndTrackKYCSubmission: "View and track KYC submission activities.",
  kycId: "KYC ID",
  driverLicenseIdID: "Driver’s License ID",
  driverLicenseIdIDNumber: "Driver’s License ID Number",
  dateApplied: "Date Applied",
  dateLastUpdated: "Date Last Updated",
  company: "Company",
  companyName: "Company Name",
  discountCampaign: "Discount Campaign",
  BPI: "BPI",
  editViewColumns: "Edit View Columns",
  selectTheAdditionalColumns: "Select the additional columns to display in the list view",
  personalInformation: "Personal Information",
  middleName: "Middle Name",
  personalDocuments: "Personal Documents",
  reject: "Reject",
  approve: "Approve",
  approveKYCrequest: "Approve KYC request?",
  areYouSureAprroveKYC: "Are you sure you want to approve this KYC request?",
  yesApproveRequest: "Yes, Approve Request",
  areyouSureYouWantToRejectKYC:
    "Are you sure you want to reject this KYC request? To proceed, input below the reason or message that you would like to display to the user.",
  rejectKYCRequest: "Reject KYC request?",
  reasonRemarks: "Reason/Remarks",
  yesRejectRequest: "Yes, Reject Request",
  autoRenew: "Auto-renew",
  autoRenewFuelCode: "Auto-renew Fuel Code every {0}",
  refsPumpPriceAtAssessment: "RefS Pump Price at Assessment",
  accuMovementSinceAssessment: "Accumulated Movement since Assessment",
  contractPriceAtOnboarding: "Contract Price at Onboarding",
  refSStationType: "Reference Station Type",
  priceAssessmentDate: "Price Assessment Date",
  kycDetails: "KYC Details",
  sentAsSms: "Sent as SMS",
  insufficientProductError: "Please add atleast 1 discount",
  startDateEndDateError: "Please add a start date and end date",
  totalDiscount: "Total Discount",
  virtualRefsPumpPrice: "Virtual RefS Pump Price",
  station: "Station",
  dateProcessed: "Date Processed",
  settlementId: "Settlement ID",
  totalLiters: "Total Liter Volume",
  discountVoucherStatus: "Discount Voucher Status",
  totalDiscountVoucher: "Total Discount Voucher to Dealer",
  totalSettlementWithSeaoil: "Total Net OilCo Share payout to SEAOIL",
  dealerSettlementPayoutStatus: "Dealer Settlement Payout Status",
  totalDealerSettlementPayout: "Total Settlement Payout to Dealer",
  settlements: "Settlements",
  settlementHistory: "Settlement History",
  viewTrackSettlement: "View and track settlements",
  forProcessing: "For Processing",
  forSettlement: "For Settlement",
  settled: "Settled",
  processed: "Processed",
  cancelSettlement: "Cancel Settlement",
  cancelSettlementMessage: "You are about to cancel settlement ID {0}. Do you want to proceed?",
  back: "Back",
  yesCancelSettlement: "Yes, Cancel Settlement",
  settlementDetails: "Settlement Details",
  periodOfStatement: "Period of Statement",
  settelmentCancelSuccess: "Settlement successfully cancelled",
  cancelSettlementSuccessMessage: "You have successfully cancelled the settlement ID {0}",
  report: "Report",
  editSettlementStatus: `Are you sure you want to update Dealer settlement payout status to "{0}"? You can't undo this action`,
  editSettlement: "Are you sure you want to save all changes to this Settlement?",
  updateStatus: "Update Status",
  processSettlement: "Process Settlement",
  processSettlementQuestion: "Process settlement?",
  processSettlementMessage:
    "You are about to process settlement for {0} from {1} to {2} Do you want to proceed?",
  successProcessSettlementMessage:
    "You can now proceed with the payment add update the settlement status in the payment history afterwards",
  goBackSettlements: "Go back to Settlements",
  goSettlementHistory: "Go to Settlement History",
  dateToBeSettled: "Date to be Settled",
  generateReport: "Generate Report",
  noDataYet: "No data yet",
  generateReportError: "Generate report error",
  noRedemptionToSettled: "There are no redemptions to be settled",
  stationBusinessNameRedemptionStation: "Settlement ID, Station Business Name, Redemption Station",
  settlementStatus: "Settlement Status",
  processSettlementIds: "Process previous settlement ID first.",
  processSettlementIdsContent:
    "There is a settlement ID that is still for processing for this station. Please process this settlement ID first before updating status of current settlement ID selected",
  processCancelSettlementIds: "Cancel newer settlement IDs first",
  processCancelSettlementIdsContent:
    "There are settlement ID/s created after the current settlement ID selected. You need to cancel the following Settlement IDs first before cancelling this settlement ID",
  unableToCancelSettlement: "Unable to Cancel Settlement ID",
  unableToCancelMessage: "You can’t cancel this settlement since this has already been processed:",
  asPdf: "Export PDF",
  asExcel: "Export Excel",
  rfidReloading: "RFID Reloading",
  viewAndTrackRFIDReloadTransaction: "View and track RFID reload transactions.",
  rfidReloadID: "RFID Reload ID",
  rfidType: "RFID Type",
  rfidAccountNumber: "RFID Account Number",
  loadAmount: "Load Amount",
  customerFee: "Customer Fee",
  reloadDate: "Reload Date",
  autosweep: "Autosweep",
  reloadID: "Reload ID",
  reloadDateRange: "Reload Date Range",
  paymentMethod: "Payment Method",
  otherServices: "Other Services",
  showOnboardingInfo: "Show Onboarding Information",
  onboardingPricingInfo: "Onboarding Pricing Information",
  currentPricingInfo: "Current Pricing Information",
  contractPrice: "Current Contract Price",
  accuSpecificMovement: "Accumulated Account-Specific Movement",
  viewAccountDetails: "View Account Details",
  viewPricingDetails: "View Pricing Details",
  pricingDetails: "Pricing Details",
  editPricingDetails: "Edit Pricing Details",
  applyPricingMovement: "Apply Account Specific Movement",
  updatedReferenceStationOnly:
    "You are updating the reference station without changing the following details.",
  withholdingTaxRefund: "Withholding Tax Refund",
  reportGenerated: "Report is being generated",
  introductoryPromoRebate: "Introductory Promo Rebate",
  applyAccountSpecificMovement: "Apply account specific movement",
  currentPumpPrice: "Current Pump Price",
  refSPumpPrice: "RefS Pump Price",
  accountSpecificMovement: "Account Specific Movement",
  proceedWithChanges: "Proceed with Changes",
  newPumpPrice: "New Pump Price",
  matchRefSPumpPrice: "Match pump price with RefS pump price",
  pumpPrice: "Pump Price",
  update: "Update",
  updateAll: "Update All",
  pricingChanges: "Pricing Changes",
  viewAndTrackChangesOnPlbInformation: "View and Track Changes on PLB account pricing information",
  applyPriceChangesToMultipleAccount: "Apply Price Changes To Multiple Accounts",
  periodCovered: "Period Covered",
  businessNameBusinessIDReferenceStation: "Business Name, Business ID, Reference Station",
  oldReferenceStation: "Old Reference Station",
  oldRedemptionStation: "Old Redemption Station",
  oldDepot: "Old Depot",
  oldSdum: "Old SDUM",
  oldFreight: "Old Freight",
  oldDiscount: "Old Discount",
  browseYourFile: "Browse Your Files",
  replace: "Replace",
  youMayGoToPricingChangesPage:
    "You may go to the Pricing Changes page to check your account pricing information",
  pleaseCheckUploadedFile: "Please check the uploaded file and try again",
  editAccountPricesFailed: "Edit Account Prices Failed",
  priceChangesSuccessfullySaved: "Price changes successfully saved!",
  goToPricingChanges: "Go to Pricing Changes",
  goBackToEditAccountPrices: "Go back to Edit Account Prices",
  pleaseSelectCSVFile: "Please select a .csv file",
  applyUpdates: "Apply Updates",
  dateEdited: "Date Edited",
  viewTrackPriveMovements: "View and track price movements",
  priceMovementId: "Price Movement ID",
  deleted: "Deleted",
  campaignGroup: "Campaign Group",
  campaignCreator: "Campaign Creator",
  shopeePay: "ShopeePay",
  campaignGroups: "Campaign Groups",
  campaignCreators: "Campaign Creators",
  allCampaignCreators: "All Campaign Creators",
  auditTrailId: "Audit Trail ID",
  customerId: "Customer ID",
  downloadSingle: "Download",
  download: "Downloads",
  externalIds: "Grab X SEAOIL VIP Card or Grab ID",
  reportType: "Report Type",
  accumulatedStationSpecificMovement: "Accumulated Station-Specific Movement since Assessment",
  dragonPay: "Dragonpay",
  aubAsiaUnitedBank: "AUB (Asia UnitedBank)",
  bankOfCommerce: "Bank of Commerce",
  bpiBankOfPhil: "BPI (Bank of the Philippine Islands)",
  chinabank: "Chinabank",
  eastwestBank: "EastWest Bank",
  landBank: "Landbank",
  mayBank: "Maybank",
  pnbPhilNatBank: "PNB (Philippine National Bank)",
  psBank: "PSBank",
  rcbcRizalCommercialBank: "RCBC (Rizal Commercial Banking Corp)",
  robinsonsBank: "Robinsons Bank",
  ucpb: "UCPB (United Coconut Planters Bank)",
  securityBank: "Security Bank",
  bdoNetworkBank: "BDO Network Bank",
  riderId: "Rider ID",
  dragAndDropFilesToUploadOr: "Drag and drop files to upload, or",
  addFile: "Add File",
  uploadingAnImportFileWill: "Uploading an import file will overwrite the existing information.",
  importFailed: "Import Failed (with Errors)",
  otherError: "Other error",
  prepayment: "Prepayment",
  errorInStatusMessage: "Error in Status/Message (row): ",
  voucherPrepayment: "Voucher Prepayment",
  viewAndTrackVoucherPrepayment: "View and track voucher prepayment activities.",
  payWithPeso: "Pay with Peso",
  payWithPesoId: "Pay with Peso ID",
  dateOfTransaction: "Date of Transaction",
  scanToPayStation: "Scan-to-Pay Station",
  paymentAmount: "Payment Amount",
  transactionDateRange: "Transaction Date Range",
  viewAndTrackScanToPayViaPeso: "View and track Scan-to-Pay via Peso Balance transactions.",
  rebate: "Rebate",
  gasUpStation: "Gas-Up Station",
  pricetracq: "Pricetracq Portal",
  stationPricing: "Station Pricing Dashboard",
  stationPricingSub: "View and track station prices in your area",
  uploadId: "Upload ID",
  brandName: "Brand Name",
  captureDate: "Captured Date",
  dateCaptured: "Date Captured",
  barangay: "Barangay",
  uploaderEmail: "Uploader Email",
  priceCapture: "Price Capture",
  areaGroup: "Area Group",
  noEmail: "No Email",
  mapView: "Map View",
  listView: "List View",
  priceLocqDOesNotHaveAccessLocation: "PriceLOCQ does not have permission to show you location",
  asOfDate: "As of {0}",
  userAccess: "User Access",
  username: "Username",
  adminAccess: "Admin Access",
  appAccess: "App Access",
  portalAccess: "Portal Access",
  monitorUserAccessToPricetracqPortal: "Monitor user access to Pricetracq Portal",
  addUser: "Add User",
  enterEmailAddressHere: "Enter email address here",
  emailAddressExist: "This email address already exists.",
  userCanNowAccessThePriceTracqApp: "User can now access the Pricetracq App",
  goToListOfUsers: "Go to List of Users",
  addAnotherUser: "Add Another User",
  deactivateUser: "Deactivate User?",
  reactivateUser: "Reactivate User?",
  youAreAboutDeactivateUser: "You are about to deactivate {0}'s account.",
  youAreAboutReactivateUser: "You are about to reactivate {0}'s account.",
  thisWillTemporaryPreventUser:
    "This will temporarily prevent {0} from doing any transaction. Do you want to proceed?",
  thisWillEnableUser: "This will enable {0} to do transactions again. Do you want to proceed?",
  userSuccessfullyRegistered: "User successfully registered!",
  youHaveSuccessfullyRegistered: "You have successfully registered {0} to the users' list.",
  registerNewUser: "Register New User",
  competitorList: "Competitor List",
  monitorCompetitorStationsToPricetracqPortal: "View and track competitor stations in your area",
  addNewCompetitorBrand: "Add New Competitor Brand",
  brand: "Brand",
  competitorOf: "Competitor Of",
  fuelCategory: "Fuel Category",
  benchMark: "Benchmark SEAOIL Product",
  createNewCompetitor: "Add New Competitor",
  addProduct: "+ Add Product",
  productName: "Product Name",
  selectFuelCategory: "Select Fuel Category",
  addCompetitor: "Add Competitor",
  youAreAboutToAddCompetitor: "You are about to add {0} to the Competitor List.",
  doYouWanttoProceedQuestion: "Do you want to proceed?",
  addCompetitorQuestion: "Add Competitor?",
  competitorAlreadyExist: "Competitor Already Exist",
  successfullyAddCompetitor: "{0} has been successfully added to the Competitor List.",
  goBackCompetitorList: "Go back to Competitor List",
  addNewCompetitor: "Add New Competitor",
  editCompetitorDetails: "Edit Competitor Details",
  areYouSureYouWantToSaveCompetitor:
    "Are you sure you want to save all changes made to this Competitor?",
  detailsHaveBeenUpdated: "Details have been successfully updated.",
  viewCompetitorDetails: "View Competitor Details",
  lastEdited: "Last edited on ",
  seaoilStationList: "SEAOIL Station List",
  viewAndTrackStationsInYourArea: "View and track stations in your area",
  addNewSeaOilStation: "Add New SEAOIL Station",
  island: "Island",
  longitudeLatitude: "Latitude/Longitude",
  longitude: "Longitude",
  latitude: "Latitude",
  createNewStation: "Add New SEAOIL Station",
  stationCodeZero: "0000",
  location: "Location",
  locationDetails: "Type the location in the input field below",
  stationDetails: "Station Details",
  youAreAboutToAddStation: "You are about to add {0} to the Station List.",
  addNewSeaOilStationQuestion: "Add New SEAOIL Station?",
  areYouSureYouWantToSaveStation:
    "Are you sure you want to save all changes made to this SEAOIL Station?",
  addSeaOilStation: "Add SEAOIL Station",
  seaoilStationDetails: "SEAOIL Station Details",
  goBackStationList: "Go back to Station List",
  noProducts: "No Products",
  doYouWantToProceedQuestion: "Do you want to proceed?",
  successfullyAddStation: "{0} Station has been successfully created.",
  stationAlreadyExist: "Station already exists",
  stationNotFound: "Station not found",
  stationHasNoDepot: "Station has no depot",
  viewAll: "View All",
  competitorStations: "Competitor Stations",
  uploadID: "Upload ID",
  others: "Others",
  NA: "N/A",
};

export default Object.freeze(locale);
