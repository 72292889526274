import StationPricingModule from "modules/station-pricing/station-pricing.module";
import React from "react";

const StationPricingPage = () => {
  return (
    <div>
      <StationPricingModule />
    </div>
  );
};

export default StationPricingPage;
