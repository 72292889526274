import React from "react";

import styles from "./user-access-filter.module.scss";

import { Field, SearchField } from "components/commons";
import locale from "localization";

const UserAccessFilter = ({ searchKey, modifySearch, applySearch, applyClearSearch }) => {
  return (
    <div className={styles?.container}>
      <div className={styles?.filter}>
        <Field className={styles.search}>
          <SearchField
            restrict={false}
            searchWithSymbols
            placeholder={[locale.emailAddress].join(", ")}
            value={searchKey}
            onChange={(_, { value }) => {
              modifySearch(value);
            }}
            onEnter={applySearch}
            onClear={applyClearSearch}
          />
        </Field>
      </div>
    </div>
  );
};

export default UserAccessFilter;
