const AccessAuthorization = "/access-authorization";
const Competitor = "/competitor";
const Station = "/station";
const Path = {
  Slash: "/",
  Auth: "/auth",
  AccessAuthorization: AccessAuthorization,
  UserAccess: `${AccessAuthorization}/user-access`,
  Competitor: Competitor,
  CompetitorList: `${Competitor}/list`,
  AddCompetitor: `${Competitor}/add`,
  EditCompetitor: `${Competitor}/edit`,
  ViewCompetitor: `${Competitor}/view`,
  StationList: `${Station}/list`,
  AddStation: `${Station}/add`,
  EditStation: `${Station}/edit`,
  ViewStation: `${Station}/view`,
};

export default Path;
