import { PopOverMenu } from "components/commons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { locale } from "localization/en";
import { capitalize } from "utils/text.utils";
import styles from "./competitor-list.module.scss";
import { prettifyFuelCategory, prettifyProductCode } from "utils/pretty.utils";

export const mapDataToList = ({ brands = {}, handleUpdateCompetitor, handleViewCompetitor }) => {
  const { brandId, brandProducts, brandName, updatedAt } = brands;
  const options = [
    {
      removable: true,
      content: locale.viewDetails,
      onClick: () => {
        handleViewCompetitor({
          brandId,
          brandProducts,
          updatedAt,
          brandName,
        });
      },
    },
    {
      removable: true,
      content: locale.editDetails,
      onClick: () => {
        handleUpdateCompetitor({
          brandId,
          brandProducts,
          updatedAt,
          brandName,
        });
      },
    },
  ];

  // let products = _.orderBy(competitorProducts, "productName", "asc");

  const brandProductList = brandProducts.map((item) => {
    return (
      <>
        {capitalize(item.productName)} <br />
      </>
    );
  });

  const brandFuelCategories = brandProducts.map((item) => {
    return (
      <>
        {capitalize(prettifyFuelCategory(item.fuelCategory))}
        <br />
      </>
    );
  });

  const brandBenchMarks = brandProducts.map((item) => {
    return (
      <>
        {capitalize(prettifyProductCode(item.productCode))} <br />
      </>
    );
  });

  return {
    brand: brandName,
    product: brandProductList,
    fuelCategory: brandFuelCategories,
    benchMark: brandBenchMarks,
    actions: (
      <PopOverMenu options={options}>
        <MoreVertIcon className={styles.icon} />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { ...fs } = filterState;
  return {
    ...fs,
  };
};
