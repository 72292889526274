import React, { useState, useCallback, useEffect } from "react";
import locale from "localization";
import styles from "./station-details.module.scss";
import { Field, TextField, Title, Button, Text, SearchField } from "components/commons";
import classNames from "classnames";
import { prettifyProductCode } from "utils/pretty.utils";
import { ProductCodeEnum, ErrorCode } from "enums";
import StationMap from "components/commons/station-map-list/station-map";
import { validateStationCode, getStationByStationCode } from "apis/station.api";
import { useApi, useMount } from "hooks";
import { handleRequest } from "utils";
import { capitalizeAll, capitalize, capitalizeWord } from "utils/text.utils";

const StationDetailsFormModule = ({ ...stateForm }) => {
  const {
    viewMode,
    addMode,
    fields,
    modifyField,
    loading,
    handleSubmit,
    applyFieldErrors,
    setDetails,
    details,
    formValid,
    getFormValues,
    setIsValidCoordinates,
  } = stateForm;

  const [currentLocation, setCurrentLocation] = useState(null);
  const [allowAccessLocation, setAllowAccessLocation] = useState(true);
  const [hasSearchLocation, setHasSearchLocation] = useState(false);

  // const [pinnedMap, setPinnedMap] = useState(null);

  const getCurrentPosition = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        if (position) {
          setCurrentLocation({
            lat: Number(0),
            lng: Number(0),
          });
        }
      },
      () => {
        setAllowAccessLocation(false);
      }
    );
  }, []);

  useMount(() => {
    getCurrentPosition();
  });

  const { request: validateStationCodeRequest, loading: validatingStationCode } = useApi({
    api: validateStationCode,
    modalError: false,
    handleOwnError: {
      badrequest: true,
    },
  });

  const { request: getStationByStationCodeRequest, loading: loadingGetStationByStationCode } =
    useApi({
      api: getStationByStationCode,
      modalError: false,
      handleOwnError: {
        badrequest: true,
      },
    });

  const onEnterStationCodeCb = useCallback(() => {
    const { stationCode } = getFormValues();

    const applyStationCodeError = (message) => {
      applyFieldErrors(
        {
          stationCode: message,
        },
        {
          stationCode: {
            value: stationCode,
          },
        }
      );
    };
    if (stationCode && stationCode.length === 4) {
      handleRequest(
        async () => {
          const checkIfExist = await validateStationCodeRequest({
            stationCode,
          });

          if (checkIfExist) {
            applyStationCodeError(locale.stationAlreadyExist);
          }
        },
        {},
        async (err) => {
          setDetails({});
          switch (err.data?.errorCode) {
            case ErrorCode.StationNotFoundv1:
              handleRequest(
                async () => {
                  const res = await getStationByStationCodeRequest({
                    stationCode,
                  });

                  if (res) {
                    modifyField("stationCode", { value: stationCode });
                    setDetails({
                      ...res,
                      province: res?.area,
                      island: res?.island,
                      stationProduct: res?.availableProducts,
                      name: res?.stationName,
                    });
                  }
                },
                {},
                async (err) => {
                  setDetails({});
                  switch (err.data?.errorCode) {
                    case ErrorCode.StationPriceToolNotFound:
                      applyStationCodeError(locale.stationNotFound);
                      break;
                    case ErrorCode.StationHasNoDepot:
                      applyStationCodeError(locale.stationHasNoDepot);
                      break;
                    default:
                      return err.showError(true);
                  }
                }
              );
              break;
            default:
              return err.showError(true);
          }
        }
      );
    } else {
      setDetails({});
    }
  }, [
    getFormValues,
    applyFieldErrors,
    validateStationCodeRequest,
    setDetails,
    getStationByStationCodeRequest,
    modifyField,
  ]);

  const applyEnter = useCallback(() => {
    setHasSearchLocation(true);
  }, [setHasSearchLocation]);

  useEffect(() => {
    if (viewMode) {
      setHasSearchLocation(true);
    }
  }, [viewMode]);

  const detailsMapProps = {
    currentLocation,
    allowAccessLocation,
    hasSearchLocation,
    fields,
    setIsValidCoordinates,
    modifyField,
  };
  return (
    <div className={styles.container}>
      <Title black xsmall>
        {locale.basicInformation}
      </Title>
      <Field {...fields?.stationCode}>
        {viewMode ? (
          <Text className={styles.fieldText} style={{ color: "black" }}>
            {fields?.stationCode?.value}
          </Text>
        ) : (
          <TextField
            maxLength={4}
            {...fields?.stationCode}
            onChange={modifyField}
            disabled={validatingStationCode || loadingGetStationByStationCode}
            inputDisabled={validatingStationCode || loadingGetStationByStationCode}
            loading={validatingStationCode || loadingGetStationByStationCode}
            onEnter={() => {
              onEnterStationCodeCb();
            }}
          />
        )}
      </Field>

      {Object.keys(details).length > 0 && (
        <>
          {viewMode ? (
            ""
          ) : (
            <Title black xsmall>
              {locale.stationDetails}
            </Title>
          )}
          <div className={styles.tableStationDetails}>
            <div className={styles.row}>
              <label>{locale.stationType}</label>
              <label>{details?.stationType || locale.any}</label>
            </div>
            <div className={styles.row}>
              <label>{locale.stationName}</label>
              <label>{details?.name ? capitalizeAll(details?.name) : ""}</label>
            </div>
            <div className={styles.row}>
              <label>{locale.island}</label>
              <label>{details?.island ? capitalize(details?.island) : ""}</label>
            </div>
            <div className={styles.row}>
              <label>{locale.province}</label>
              <label>{details?.province ? capitalizeWord(details?.province) : ""}</label>
            </div>
            {addMode && (
              <div className={styles.row}>
                <label>{locale.products}</label>
                <label>
                  {details?.stationProduct && (
                    <div className={styles.productList}>
                      {details?.stationProduct?.includes(ProductCodeEnum.DIESEL) && (
                        <div>
                          <div className="flex items-center">
                            <div
                              className={classNames(styles.circle, styles[ProductCodeEnum.DIESEL])}
                            ></div>
                            {prettifyProductCode(ProductCodeEnum.DIESEL)}
                          </div>
                        </div>
                      )}
                      {details?.stationProduct?.includes(ProductCodeEnum.GAS91) && (
                        <div>
                          <div className="flex items-center">
                            <div
                              className={classNames(styles.circle, styles[ProductCodeEnum.GAS91])}
                            ></div>
                            {prettifyProductCode(ProductCodeEnum.GAS91)}
                          </div>
                        </div>
                      )}
                      {details?.stationProduct?.includes(ProductCodeEnum.GAS95) && (
                        <div>
                          <div className="flex items-center">
                            <div
                              className={classNames(styles.circle, styles[ProductCodeEnum.GAS95])}
                            ></div>
                            {prettifyProductCode(ProductCodeEnum.GAS95)}
                          </div>
                        </div>
                      )}
                      {details?.stationProduct?.includes(ProductCodeEnum.GAS97) && (
                        <div>
                          <div className="flex items-center">
                            <div
                              className={classNames(styles.circle, styles[ProductCodeEnum.GAS97])}
                            ></div>
                            {prettifyProductCode(ProductCodeEnum.GAS97)}
                          </div>
                        </div>
                      )}
                      {details?.stationProduct?.includes(ProductCodeEnum.GAS105) && (
                        <div>
                          <div className="flex items-center">
                            <div
                              className={classNames(styles.circle, styles[ProductCodeEnum.GAS105])}
                            ></div>
                            {prettifyProductCode(ProductCodeEnum.GAS105)}
                          </div>
                        </div>
                      )}
                      {details?.stationProduct?.includes(ProductCodeEnum.KEROSENE) && (
                        <div>
                          <div className="flex items-center">
                            <div
                              className={classNames(
                                styles.circle,
                                styles[ProductCodeEnum.KEROSENE]
                              )}
                            ></div>
                            {prettifyProductCode(ProductCodeEnum.KEROSENE)}
                          </div>
                        </div>
                      )}
                      {details?.stationProduct?.includes(ProductCodeEnum.LPG) &&
                        details?.stationProduct?.lpg && (
                          <div>
                            <div className="flex items-center">
                              <div
                                className={classNames(styles.circle, styles[ProductCodeEnum.LPG])}
                              ></div>
                              {prettifyProductCode(ProductCodeEnum.LPG)}
                            </div>
                          </div>
                        )}
                    </div>
                  )}
                </label>
              </div>
            )}
          </div>
        </>
      )}

      {viewMode && details && (
        <>
          <Title black xsmall>
            {locale.products}
          </Title>
          <div className={styles.row}>
            <label>
              {details?.stationProduct ? (
                <div className={styles.productList}>
                  {details?.stationProduct?.hasOwnProperty(ProductCodeEnum.DIESEL) &&
                    details?.stationProduct?.diesel && (
                      <div>
                        <div className="flex items-center">
                          <div
                            className={classNames(styles.circle, styles[ProductCodeEnum.DIESEL])}
                          ></div>
                          {prettifyProductCode(ProductCodeEnum.DIESEL)}
                        </div>
                      </div>
                    )}
                  {details?.stationProduct?.hasOwnProperty(ProductCodeEnum.GAS91) &&
                    details?.stationProduct?.gas91 && (
                      <div>
                        <div className="flex items-center">
                          <div
                            className={classNames(styles.circle, styles[ProductCodeEnum.GAS91])}
                          ></div>
                          {prettifyProductCode(ProductCodeEnum.GAS91)}
                        </div>
                      </div>
                    )}
                  {details?.stationProduct?.hasOwnProperty(ProductCodeEnum.GAS95) &&
                    details?.stationProduct?.gas95 && (
                      <div>
                        <div className="flex items-center">
                          <div
                            className={classNames(styles.circle, styles[ProductCodeEnum.GAS95])}
                          ></div>
                          {prettifyProductCode(ProductCodeEnum.GAS95)}
                        </div>
                      </div>
                    )}
                  {details?.stationProduct?.hasOwnProperty(ProductCodeEnum.GAS97) &&
                    details?.stationProduct?.gas97 && (
                      <div>
                        <div className="flex items-center">
                          <div
                            className={classNames(styles.circle, styles[ProductCodeEnum.GAS97])}
                          ></div>
                          {prettifyProductCode(ProductCodeEnum.GAS97)}
                        </div>
                      </div>
                    )}
                  {details?.stationProduct?.hasOwnProperty(ProductCodeEnum.GAS105) &&
                    details?.stationProduct?.gas105 && (
                      <div>
                        <div className="flex items-center">
                          <div
                            className={classNames(styles.circle, styles[ProductCodeEnum.GAS105])}
                          ></div>
                          {prettifyProductCode(ProductCodeEnum.GAS105)}
                        </div>
                      </div>
                    )}
                  {details?.stationProduct?.hasOwnProperty(ProductCodeEnum.KEROSENE) &&
                    details?.stationProduct?.kerosene && (
                      <div>
                        <div className="flex items-center">
                          <div
                            className={classNames(styles.circle, styles[ProductCodeEnum.KEROSENE])}
                          ></div>
                          {prettifyProductCode(ProductCodeEnum.KEROSENE)}
                        </div>
                      </div>
                    )}
                  {details?.stationProduct?.hasOwnProperty(ProductCodeEnum.LPG) &&
                    details?.stationProduct?.lpg && (
                      <div>
                        <div className="flex items-center">
                          <div
                            className={classNames(styles.circle, styles[ProductCodeEnum.LPG])}
                          ></div>
                          {prettifyProductCode(ProductCodeEnum.LPG)}
                        </div>
                      </div>
                    )}
                </div>
              ) : (
                <label>{locale.noProducts}</label>
              )}
            </label>
          </div>
        </>
      )}

      <Title black xsmall>
        {locale.location}
      </Title>
      {viewMode ? (
        <div className={styles.tableStationDetails}>
          <div className={styles.row}>
            <label>{locale.longitudeLatitude}</label>
            <label>{fields?.stationLocation?.value}</label>
          </div>
        </div>
      ) : (
        <Text label style={{ marginBottom: "20px  " }}>
          {locale.locationDetails}
        </Text>
      )}

      <div className={styles.parentMap}>
        <StationMap {...detailsMapProps}></StationMap>
        {viewMode ? (
          ""
        ) : (
          <Field className={styles.search}>
            <SearchField
              className={styles.inputFieldSearch}
              restrict={false}
              searchWithSymbols
              placeholder={[locale.latitude, locale.longitude].join(" / ")}
              value={fields?.stationLocation?.value}
              onEnter={applyEnter}
              onClear={() => {
                modifyField(fields?.stationLocation?.name, { value: "" });
              }}
              onChange={(_, { value }) => {
                modifyField(fields?.stationLocation?.name, { value });
              }}
            />
          </Field>
        )}
      </div>

      {addMode && (
        <Button
          primary
          className={styles.continue}
          loading={loading}
          disabled={!formValid}
          onClick={handleSubmit}
        >
          {locale.continue}
        </Button>
      )}
    </div>
  );
};

export default StationDetailsFormModule;
