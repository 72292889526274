import { capitalize } from "utils";

export const stationFormPost = (data, stationData) => {
  const location = data?.stationLocation?.split(",");
  const dataProduct = stationData?.stationProduct;

  return {
    name: capitalize(stationData?.name),
    stationCode: Number(stationData?.stationCode),
    stationType: stationData?.stationType,
    latitude: Number(location[0]) ?? null,
    longitude: Number(location[1]) ?? null,
    area: stationData?.province,
    island: stationData?.island,
    products: dataProduct,
  };
};

export const stationFormGet = (data) => {
  const lat = data?.latitude ?? "";
  const long = data?.longitude ?? "";
  const location = lat === "" && long === "" ? "" : `${lat},${long}`;
  return {
    stationCode: { value: data?.stationCode },
    stationLocation: { value: location },
  };
};
