import React, {
  useMemo,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
} from "react";
import { useForm, useApi, useModal, useMount } from "hooks";
import { FormMode, Path } from "enums";
import { initialState as formState } from "./competitor-details.state";
import CompetitorDetailsFormModule from "./competitor-details-form.module";
import styles from "./competitor-details.module.scss";
import locale from "localization";
import { Loader } from "components/commons";
import { handleRequest } from "utils";
import { ConfirmModal } from "components/modals";
import { useHistory } from "react-router-dom";
import { createCompetitor, updateCompetitor, getCompetitor } from "apis";
import { competitorFormPost, competitorFormGet } from "./competitor-form.utils";

const DiscountDetailsModule = ({ pageMode, brandId, stationCount, ...props }, ref) => {
  const { setUpdateLoading, setDisableUpdate } = props;
  const viewMode = pageMode === FormMode.View;
  const addMode = pageMode === FormMode.Add;
  const editMode = pageMode === FormMode.Edit;
  const [formValid, setFormValid] = useState(false);
  const [attributeInput, setAttributeInput] = useState([
    { productName: "", fuelCategory: "", productCode: "" },
  ]);

  const {
    request: getCompetitorRequest,
    loading: loadingCompetitor,
    result: getCompetitorResult,
  } = useApi({
    api: getCompetitor,
    pageError: true,
    params: {
      brandId,
    },
  });

  const addRow = () => {
    if (editMode) {
      setDisableUpdate(false);
    }
    setAttributeInput([...attributeInput, { productName: "", fuelCategory: "", productCode: "" }]);
  };

  const onRemove = (i) => {
    const newForm = [...attributeInput];
    newForm.splice(i, 1);
    setAttributeInput(newForm);
    if (editMode) {
      setDisableUpdate(false);
    }
  };
  const onChangeProductName = (value, i) => {
    if (editMode) {
      setDisableUpdate(false);
    }
    let inValid = true;
    const newForm = [...attributeInput];
    newForm[i]["productName"] = value;
    if (value.length > 0) {
      inValid = false;
    }
    newForm[i].productNameCheck = inValid;
    setAttributeInput(newForm);
  };

  const onChangeFuelCategory = (value, i) => {
    if (editMode) {
      setDisableUpdate(false);
    }
    let inValid = true;
    const newForm = [...attributeInput];
    newForm[i]["fuelCategory"] = value;
    if (value.length > 0) {
      inValid = false;
    }
    newForm[i].fuelCategoryCheck = inValid;
    setAttributeInput(newForm);
  };

  const onChangeProduct = (value, i) => {
    if (editMode) {
      setDisableUpdate(false);
    }
    let inValid = true;
    const newForm = [...attributeInput];
    newForm[i]["productCode"] = value;
    if (value.length > 0) {
      inValid = false;
    }
    newForm[i].productNameCheck = inValid;
    setAttributeInput(newForm);
  };

  const formAttributeValidation = useCallback((formVal) => {
    const data = [...formVal];
    const errors = [];
    let validProductName;
    let validFuelCategory;
    let validProductCode;
    data.forEach((product) => {
      if (product.productName === "") {
        product.productNameCheck = true;
        validProductName = false;
      } else {
        product.productNameCheck = false;
        validProductName = true;
      }

      if (product.fuelCategory === "") {
        product.fuelCategoryCheck = true;
        validFuelCategory = false;
      } else {
        product.fuelCategoryCheck = false;
        validFuelCategory = true;
      }

      if (product.productCode === "") {
        product.productCodeCheck = true;
        validProductCode = false;
      } else {
        product.productCodeCheck = false;
        validProductCode = true;
      }
      errors.push(validProductName, validFuelCategory, validProductCode);
    });
    setAttributeInput(data);
    const allValid = errors.every((element) => element === true);

    return allValid;
  }, []);

  const form = useMemo(() => {
    let initialState = {};
    if (brandId) {
      const data = getCompetitorResult;
      if (data) {
        const { brandProducts } = data;
        initialState = competitorFormGet(data);
        setAttributeInput(brandProducts);
      }
    }
    return formState(initialState);
  }, [brandId, getCompetitorResult]);

  const confirmModal = useModal();
  const { show, close } = confirmModal;
  const history = useHistory();

  useMount(async () => {
    if (brandId) {
      await getCompetitorRequest();
    }
  });

  const {
    fields,
    modifyField,
    isFormSubmittable,
    submitForm,
    modifyForm,
    clearForm,
    getFormValues,
    applyFieldErrors,
  } = useForm({
    initialState: form,
  });

  const formAttributeValidationv2 = useCallback(
    (formVal) => {
      const data = [...formVal];
      const errors = [];
      let validProductName;
      let validFuelCategory;
      let validProductCode;
      let validBrand;
      if (fields.brandName.value === "") {
        validBrand = false;
      } else {
        validBrand = true;
      }
      data.forEach((product) => {
        if (product.productName === "") {
          validProductName = false;
        } else {
          validProductName = true;
        }

        if (product.fuelCategory === "") {
          validFuelCategory = false;
        } else {
          validFuelCategory = true;
        }

        if (product.productCode === "") {
          validProductCode = false;
        } else {
          validProductCode = true;
        }
        errors.push(validProductName, validFuelCategory, validProductCode, validBrand);
      });
      const allValid = errors.every((element) => element === true);

      return allValid;
    },
    [fields?.brandName?.value]
  );

  const addRequest = useApi({
    api: createCompetitor,
    handleOwnError: {
      badrequest: true,
    },
  });

  const editRequest = useApi({
    api: updateCompetitor,
    handleOwnError: {
      badrequest: true,
    },
    params: {
      brandId,
    },
  });

  const loading = addRequest.loading || editRequest.loading;

  const submit = (params) => {
    const apiRequest = addMode ? addRequest : editRequest;
    handleRequest(
      async () => {
        close();
        await apiRequest.request(
          {
            ...params,
          },
          () => handleSubmit()
        );
        clearForm();
        setAttributeInput([{ productName: "", fuelCategory: "", productCode: "" }]);
        if (addMode) {
          show({
            title: locale.exclamatedSuccess,
            content: (
              <locale.Populate
                text={locale.successfullyAddCompetitor}
                items={[<b>{params.name}</b>]}
              />
            ),
            secondary: {
              text: locale.goBackCompetitorList,
              onClick: () => {
                history.push(Path.CompetitorList);
              },
            },
            primary: {
              text: locale.addNewCompetitor,
              onClick: () => {
                close();
              },
            },
          });
        } else {
          await getCompetitorRequest();
          show({
            title: locale.exclamatedSuccess,
            content: locale.detailsHaveBeenUpdated,
            primary: {
              text: locale.gotIt,
              onClick: () => {
                history.push(Path.ViewCompetitor, {
                  brandId,
                });
              },
            },
          });
        }
      },
      {
        DC1001: () => {
          applyFieldErrors({
            brand: locale.competitorAlreadyExist,
          });
        },
      }
    );
  };

  const handleSubmit = () => {
    const currentFormValues = getFormValues();
    const errorRes = formAttributeValidation(attributeInput);
    let payload = competitorFormPost(currentFormValues, attributeInput);
    if (errorRes) {
      show({
        title: addMode ? locale.addCompetitorQuestion : locale.saveChangesQuestion,
        content: addMode ? (
          <>
            <locale.Populate
              text={locale.youAreAboutToAddCompetitor}
              items={[<b>{payload.brandName}</b>]}
            />
            <br />
            <locale.Populate text={locale.doYouWanttoProceedQuestion} items={[]} />
          </>
        ) : (
          locale.areYouSureYouWantToSaveCompetitor
        ),
        secondary: {
          text: addMode ? locale.Cancel : locale.continueEditing,
        },
        primary: {
          text: addMode ? locale.addCompetitor : locale.saveChanges,
          onClick: () => {
            submit(payload);
          },
        },
      });
    }
  };

  useImperativeHandle(ref, () => ({
    handleUpdate() {
      submitForm(handleSubmit);
    },
  }));

  useEffect(() => {
    if (editMode) {
      setUpdateLoading(loading);
      setDisableUpdate(!isFormSubmittable);
    }
  }, [
    editMode,
    isFormSubmittable,
    loading,
    setDisableUpdate,
    setUpdateLoading,
    formAttributeValidation,
  ]);

  useEffect(() => {
    if (!editMode) {
      if (isFormSubmittable === true && formAttributeValidationv2(attributeInput) === true) {
        setFormValid(true);
      } else {
        setFormValid(false);
      }
    }
  }, [editMode, isFormSubmittable, formAttributeValidationv2, attributeInput]);

  const detailsFormProps = {
    viewMode,
    addMode,
    editMode,
    fields,
    isFormSubmittable,
    loading,
    modifyField,
    submitForm,
    modifyForm,
    handleSubmit,
    attributeInput,
    addRow,
    onRemove,
    onChangeProductName,
    onChangeFuelCategory,
    onChangeProduct,
    getCompetitorRequest,
    brandId,
    formValid,
  };

  return (
    <div>
      <Loader open={loadingCompetitor} />
      {!loadingCompetitor && (
        <div className={styles.container}>
          {!viewMode && (
            <>
              <ConfirmModal {...confirmModal} />
            </>
          )}
          <CompetitorDetailsFormModule {...detailsFormProps} />
        </div>
      )}
    </div>
  );
};

export default forwardRef(DiscountDetailsModule);
