import React from "react";
import { useModal } from "hooks";
import locale from "localization";
import styles from "./station-add.module.scss";
import { FormMode } from "enums";
import { Path } from "enums";
import { Intro, BackLink } from "components/commons";
import StationDetailsModule from "../station-details/station-details.module";

const StationAddModule = () => {
  const successModal = useModal();

  return (
    <div>
      <BackLink text={locale.stationAccountList} path={Path.StationList} />
      <div className={styles.intro}>
        <Intro black title={locale.createNewStation} />
        <StationDetailsModule pageMode={FormMode.Add} {...successModal} stationData={{}} />
      </div>
    </div>
  );
};

export default StationAddModule;
