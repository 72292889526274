import React from "react";
import locale from "localization";
import styles from "./station-view.module.scss";
import { FormMode } from "enums";
import { Path } from "enums";
import { Intro, BackLink } from "components/commons";
import StationDetailsModule from "../station-details/station-details.module";

const StationViewModule = (props) => {
  const params = props.location.state ? props.location.state.params : "";
  return (
    <div>
      <BackLink text={locale.stationAccountList} path={Path.StationList} />
      <div className={styles.intro}>
        <Intro title={locale.seaoilStationDetails} />
        <StationDetailsModule pageMode={FormMode.View} stationData={params} />
      </div>
    </div>
  );
};

export default StationViewModule;
