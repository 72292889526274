import React, { useMemo } from "react";

import styles from "./station-pricing-filter.module.scss";

import { Cities, AreaGroup, Provinces } from "assets";
import {
  Field,
  Filter,
  DateRange,
  SearchField,
  Autocomplete,
  Button,
  TextField,
} from "components/commons";
import locale from "localization";
import { SelectBrands, SelectStations } from "components/field";
import { ListRounded, MapRounded } from "@material-ui/icons";
import { numberWithComma } from "utils/text.utils";

const StationPricingFilter = ({
  filterState,
  modifyFilter,
  filterCount,
  applyFilter,
  applyClearFilter,
  searchKey,
  modifySearch,
  applySearch,
  applyClearSearch,
  resetFilter,
  openMap,
  setOpenMap,
  updateMap,
  exporting,
  exportReport,
}) => {
  const areaGroupOptions = useMemo(() => {
    return AreaGroup.map((data) => ({
      provinces: [...data?.provinces],
      label: data?.name,
      value: data?.name,
    }));
  }, []);

  const provinceGroupOption = useMemo(() => {
    // const d = [];
    return areaGroupOptions
      .filter(({ value }) => {
        return filterState?.areaGroup.value.includes(value) || !filterState?.areaGroup.value.length;
      })
      .map(({ provinces }) => provinces)
      .flat()
      .map((province) => {
        return {
          label: province,
          value: province,
        };
      });
  }, [filterState?.areaGroup, areaGroupOptions]);

  const cityGroupOption = useMemo(() => {
    return Cities.filter(({ province }) => {
      const p = Provinces.filter(({ name }) => {
        return filterState?.province.value.includes(name);
      }).map(({ key }) => key);
      return filterState?.province.value.length ? p.includes(province) : true;
    })
      .flat()
      .map(({ name }) => {
        return {
          label: name,
          value: name,
        };
      });
  }, [filterState?.province]);
  // const buttonModeIcon = openMap ? "icon-list" : "icon-map";

  return (
    <div className={styles?.container}>
      <div className={styles?.filter}>
        <Filter
          close={resetFilter}
          clear={applyClearFilter}
          submit={() => {
            applyFilter();
            // updateMap();
          }}
          filterCount={filterCount}
        >
          <Field
            className={styles?.date}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            label={locale?.dateCaptured}
          >
            <DateRange
              value={{
                startDate: filterState.startDate,
                endDate: filterState.endDate,
              }}
              onChange={(_, { value }) => {
                const { startDate, endDate } = value;
                modifyFilter({ startDate, endDate });
              }}
            />
          </Field>
          <Field
            className={styles.fieldLabel}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            label={locale?.brandName}
          >
            <SelectBrands
              {...filterState?.brand}
              isMultiple
              disableAllOption
              allSelectedText={locale.allBrands}
              selectAllLabel={locale?.all}
              onChange={(name, { value, label, isAll }) => {
                modifyFilter({
                  brand: {
                    value,
                    label,
                    isAll,
                  },
                });
              }}
            />
          </Field>
          <Field
            className={styles.fieldLabel}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            label={locale?.competitorOf}
          >
            <SelectStations
              {...filterState?.competitorOf}
              isMultiple
              disableAllOption
              allSelectedText={locale.allStations}
              selectAllLabel={locale?.all}
              onChange={(name, { value, label, isAll }) => {
                modifyFilter({
                  competitorOf: {
                    value,
                    label,
                    isAll,
                  },
                });
              }}
            />
          </Field>
          <Field
            className={styles.fieldLabel}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            label={locale?.areaGroup}
          >
            <Autocomplete
              multiple
              hasAll
              toggleAll
              {...filterState?.areaGroup}
              options={areaGroupOptions}
              onChange={(_, { value, isAll }) => {
                modifyFilter({
                  areaGroup: { value, isAll },
                  province: {
                    isAll: true,
                    value: [],
                  },
                  city: {
                    isAll: true,
                    value: [],
                  },
                });
              }}
            />
          </Field>

          <Field
            className={styles.fieldLabel}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            label={locale?.province}
          >
            <Autocomplete
              hasAll
              multiple
              toggleAll
              name="province"
              disabled={filterState?.areaGroup?.length === 0}
              {...filterState?.province}
              options={provinceGroupOption}
              onChange={(_, { value, isAll }) => {
                modifyFilter({
                  province: { value, isAll },
                  city: {
                    isAll: true,
                    value: [],
                  },
                });
              }}
            />
          </Field>
          <Field
            className={styles.fieldLabel}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            label={locale?.city}
          >
            <Autocomplete
              hasAll
              multiple
              toggleAll
              disabled={provinceGroupOption?.length === 0}
              {...filterState?.city}
              options={cityGroupOption}
              onChange={(_, { value, isAll }) => {
                modifyFilter({ city: { value, isAll } });
              }}
            />
          </Field>

          <Field
            className={styles.fieldLabel}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            label={locale?.uploadID}
          >
            <TextField
              {...filterState.uploadIds}
              value={filterState.uploadIds}
              onChange={(_, { value }) => {
                if (numberWithComma(value)) {
                  modifyFilter({ uploadIds: value });
                }
              }}
            />
          </Field>
        </Filter>
        <Field className={styles.search}>
          <SearchField
            restrict={false}
            searchWithSymbols
            placeholder={[
              locale.stationName,
              locale.brandName,
              locale.uploadId,
              locale.product,
            ].join(", ")}
            value={searchKey}
            onChange={(_, { value }) => {
              modifySearch(value);
            }}
            onEnter={applySearch}
            onClear={applyClearSearch}
          />
        </Field>
        <Field className={styles.downloadButton}>
          <Button
            onClick={() => setOpenMap(!openMap)}
            exportButton
            startIcon={!openMap ? <MapRounded /> : <ListRounded />}
          >
            {openMap ? locale.listView : locale.mapView}
          </Button>
          <Button
            startIcon={<span className="icon-download" />}
            onClick={exportReport}
            exportButton
            loading={exporting}
          >
            {locale.export}
          </Button>
        </Field>
      </div>
    </div>
  );
};

export default StationPricingFilter;
