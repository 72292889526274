import numeral from "numeral";
import { formatAmount } from "./text.utils";

export const isLiterValid = (liter) => {
  const regex = new RegExp(/[^0-9]/);
  const val = liter.toString().split(".");

  if (val.length > 2) {
    return false;
  }

  if (!val[1]) {
    val.push("0");
  }

  if (val[1].length > 3) {
    return false;
  }

  return !regex.test(val[0]) && !regex.test(val[1]);
};

export const isPesoValid = (peso) => {
  const regex = new RegExp(/[^0-9]/);
  const val = peso.toString().split(".");

  if (val.length > 2) {
    return false;
  }

  if (!val[1]) {
    val.push("0");
  }

  if (val[1].length > 4) {
    return false;
  }

  return !regex.test(val[0]) && !regex.test(val[1]);
};

export const isAmountValid = (amount) => {
  const regex = new RegExp(/[^0-9]/);
  const regexMain = new RegExp(/^[-]?\d*$/);
  const val = amount.toString().split(".");
  if (val.length > 2) {
    return false;
  }

  if (!val[1]) {
    val.push("0");
  }

  if (val[1].length > 2) {
    return false;
  }

  return regexMain.test(val[0]) && !regex.test(val[1]);
};

export const formatNumber = (num, decimalPlaces = 3) => {
  return `${Number(num).toLocaleString("en", {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  })}`;
};

export const parseNumber = (num) => {
  return numeral(num).value();
};

export const roundUp = (num) => {
  return Number(numeral(num).format("00.000"));
};

export const formatCurrency = (num, prefix = "₱", decimalPlace = 2) => {
  return `${prefix} ${formatNumber(num, decimalPlace)}`;
};

export const formatCurrencyWithoutDecimal = (num, prefix = "₱") => {
  return `${prefix} ${num}`;
};

export const formatPesoWithDecimalRange = (num, decimalPlaces = 2) => {
  return `₱ ${formatNumber(num, decimalPlaces)}`;
};

export const formatVolume = (num, suffix = "L") => {
  return `${formatNumber(num)} ${suffix}`;
};

export const formatVolumeWithoutDecimal = (num, suffix = "L") => {
  return `${num} ${suffix}`;
};

export const parseLiter = (num) => {
  return parseNumber(num.toString().replace(" L", ""));
};

export const formatAmountPerLiter = (num) => {
  return `${formatAmount(num)} / L`;
};

export const formatBytes = (bytes, decimals) => {
  if (bytes === 0 || !bytes) return "0 Bytes";
  let k = 1024,
    dm = decimals || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};
