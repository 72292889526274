import { Pill, PopOverMenu } from "components/commons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { UserStatus } from "enums";
import { locale } from "localization/en";
import { prettifyUserStatus } from "utils/pretty.utils";
import styles from "./user-access.module.scss";

export const mapDataToList = ({ users = {}, handleUpdateStatus }) => {
  const { email, status, userId } = users;

  const options = [
    {
      removable: true,
      content: locale.deactivate,
      disabled: status !== UserStatus.Active,
      onClick: () => {
        handleUpdateStatus({
          userId,
          status: UserStatus.Deactivated,
          email,
        });
      },
    },
    {
      removable: true,
      content: locale.reactivate,
      disabled: status !== UserStatus.Deactivated,
      onClick: () => {
        handleUpdateStatus({
          userId,
          status: UserStatus.Active,
          email,
        });
      },
    },
  ];

  return {
    email,
    statusDate: (
      <Pill
        grass={status === UserStatus.Active}
        cheddar={status === UserStatus.Deleted}
        deepRed={status === UserStatus.Deactivated}
      >
        {prettifyUserStatus(status)}
      </Pill>
    ),
    actions:
      status !== UserStatus.Deleted ? (
        <PopOverMenu options={options}>
          <MoreVertIcon className={styles.icon} />
        </PopOverMenu>
      ) : null,
  };
};

export const mapFilterToRequest = (filterState) => {
  const { ...fs } = filterState;
  return {
    ...fs,
  };
};
