import { ApiPath } from "enums";
import { Get, Post, Put } from "utils";

export const getCompetitor = async ({ brandId }) => {
  const res = await Get(`${ApiPath.GetCompetitors}/${brandId}`);
  return res.data;
};

export const getCompetitors = async (query) => {
  const res = await Get(`${ApiPath.GetCompetitors}`, { ...query });
  return res.data;
};

export const createCompetitor = async ({ ...query }) => {
  const res = await Post(`${ApiPath.GetCompetitors}`, query);
  return res.data;
};

export const updateCompetitor = async ({ brandId, ...body }) => {
  const res = await Put(`${ApiPath.GetCompetitors}/${brandId}`, body);
  return res.data;
};
