import locale from "localization/en";

export const columns = [
  { key: "stationCode", text: locale.stationCode, width: "250px" },
  { key: "stationType", text: locale.stationType, width: "200px" },
  { key: "stationName", text: locale.stationName, width: "200px" },
  { key: "island", text: locale.island, width: "200px" },
  { key: "province", text: locale.province, width: "200px" },
  { key: "product", text: locale.product, width: "200px" },
  { key: "map", text: locale.longitudeLatitude, width: "200px" },
  { key: "actions", text: locale.action, width: "100px" },
];
