import React from "react";
import locale from "localization";
import styles from "./competitor-details.module.scss";
import { Field, TextField, Title, Button, Text, Image, Dropdown } from "components/commons";
import { RemoveIcon } from "images";
import { prettifyFuelCategory, prettifyProductCode } from "utils/pretty.utils";
import { FuelCategoryEnum, ProductCodeEnum } from "enums";

const CompetitorDetailsFormModule = ({ ...stateForm }) => {
  const {
    viewMode,
    addMode,
    fields,
    modifyField,
    loading,
    handleSubmit,
    onChangeFuelCategory,
    onChangeProduct,
    attributeInput,
    addRow,
    onRemove,
    onChangeProductName,
    formValid,
  } = stateForm;

  return (
    <div className={styles.container}>
      <Title black small>
        {locale.basicInformation}
      </Title>
      <Field className={styles.fieldLabel} {...fields?.brandName}>
        {viewMode ? (
          <Text className={styles.fieldText} style={{ color: "black" }}>
            {fields?.brandName?.value}
          </Text>
        ) : (
          <TextField {...fields?.brandName} onChange={modifyField} />
        )}
      </Field>
      <table className={styles.tableView}>
        <thead>
          <tr>
            {viewMode && <th></th>}
            <th style={{ color: "black", fontSize: "18px" }}>{locale.products}</th>
            <th style={{ color: "black", fontSize: "18px" }}>{locale.fuelCategory}</th>
            <th style={{ color: "black", fontSize: "18px" }}>{locale.benchMark}</th>
            {!viewMode && <th></th>}
          </tr>
        </thead>
        <tbody>
          {attributeInput.map((item, i) => (
            <tr key={i}>
              {viewMode && (
                <td className={styles.productLoop} style={{ color: "#a1aeb7" }}>{`${
                  locale.product
                } ${i + 1}`}</td>
              )}

              <td className={viewMode ? styles.viewTd : styles.addOrEditTd}>
                <Field className={styles.fieldLabel}>
                  {viewMode ? (
                    <Text className={styles.fieldText} style={{ color: "black" }}>
                      {item.productName}
                    </Text>
                  ) : (
                    <TextField
                      placeholder={locale.productName}
                      value={item.productName || ""}
                      error={item.productNameCheck}
                      onChange={(_, { value }) => {
                        onChangeProductName(value, i);
                      }}
                    />
                  )}
                </Field>
              </td>
              <td className={viewMode ? styles.viewTd : styles.addOrEditTd}>
                <Field className={styles.fieldLabel}>
                  {viewMode ? (
                    <Text className={styles.fieldText} style={{ color: "black" }}>
                      {prettifyFuelCategory(item.fuelCategory)}
                    </Text>
                  ) : (
                    <Dropdown
                      className={styles.fuelCategory}
                      error={item.fuelCategoryCheck}
                      onChange={(value) => {
                        onChangeFuelCategory(value, i);
                      }}
                      placeholder={locale.selectFuelCategory}
                      value={item.fuelCategory}
                      options={[
                        {
                          label: prettifyFuelCategory(FuelCategoryEnum.GASOLINE),
                          value: FuelCategoryEnum.GASOLINE,
                        },
                        {
                          label: prettifyFuelCategory(FuelCategoryEnum.DIESEL),
                          value: FuelCategoryEnum.DIESEL,
                        },
                        {
                          label: prettifyFuelCategory(FuelCategoryEnum.KEROSENE),
                          value: FuelCategoryEnum.KEROSENE,
                        },
                        {
                          label: prettifyFuelCategory(FuelCategoryEnum.LPG),
                          value: FuelCategoryEnum.LPG,
                        },
                      ]}
                    />
                  )}
                </Field>
              </td>
              <td className={viewMode ? styles.viewTd : styles.addOrEditTd}>
                <Field className={styles.fieldLabel}>
                  {viewMode ? (
                    <Text className={styles.fieldText} style={{ color: "black" }}>
                      {prettifyProductCode(item.productCode)}
                    </Text>
                  ) : (
                    <Dropdown
                      className={styles.benchMarkProduct}
                      error={item.productCodeCheck}
                      onChange={(value) => {
                        onChangeProduct(value, i);
                      }}
                      placeholder={locale.benchMark}
                      value={item.productCode || ""}
                      options={[
                        {
                          label: prettifyProductCode(ProductCodeEnum.DIESEL),
                          value: ProductCodeEnum.DIESEL,
                        },
                        {
                          label: prettifyProductCode(ProductCodeEnum.GAS91),
                          value: ProductCodeEnum.GAS91,
                        },
                        {
                          label: prettifyProductCode(ProductCodeEnum.GAS95),
                          value: ProductCodeEnum.GAS95,
                        },
                        {
                          label: prettifyProductCode(ProductCodeEnum.GAS97),
                          value: ProductCodeEnum.GAS97,
                        },
                        {
                          label: prettifyProductCode(ProductCodeEnum.GAS105),
                          value: ProductCodeEnum.GAS105,
                        },
                        {
                          label: prettifyProductCode(ProductCodeEnum.KEROSENE),
                          value: ProductCodeEnum.KEROSENE,
                        },
                        {
                          label: prettifyProductCode(ProductCodeEnum.LPG),
                          value: ProductCodeEnum.LPG,
                        },
                      ]}
                    />
                  )}
                </Field>
              </td>
              {!viewMode && (
                <td>
                  {i === 0 ? (
                    ""
                  ) : (
                    <span onClick={() => onRemove(i)} className={styles.removeCohort}>
                      <Image src={RemoveIcon} />
                    </span>
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      {!viewMode && (
        <Button className={styles.addCustomAttributeButton} primary outline onClick={addRow}>
          {locale.addProduct}
        </Button>
      )}
      {addMode && (
        <Button
          primary
          className={styles.continue}
          loading={loading}
          disabled={!formValid}
          onClick={handleSubmit}
        >
          {locale.continue}
        </Button>
      )}
    </div>
  );
};

export default CompetitorDetailsFormModule;
