import locale from "localization";
import styles from "./station-list.module.scss";

export const stationColumns = [
  {
    key: "stationName",
    text: <span className={styles.heading}>{locale.stationName}</span>,
    width: "75%",
  },
  {
    key: "stationType",
    text: <span className={styles.heading}>{locale.stationType}</span>,
    width: "25%",
  },
];
