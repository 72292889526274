import { PopOverMenu } from "components/commons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { locale } from "localization/en";
import { capitalizeAll, capitalize, capitalizeWord } from "utils/text.utils";
import styles from "./station-list.module.scss";
import { prettifyProductCode } from "utils/pretty.utils";
import { ProductCodeEnum } from "enums";

export const mapDataToList = ({ stations = {}, handleViewStation }) => {
  const {
    area,
    island,
    latitude,
    longitude,
    name,
    stationCode,
    stationProduct,
    stationId,
    stationType,
  } = stations;
  console.log("stations", stations);
  const options = [
    {
      removable: true,
      content: locale.viewDetails,
      onClick: () => {
        handleViewStation({
          area,
          island,
          latitude,
          longitude,
          name,
          stationCode,
          stationId,
          stationProduct,
          stationType,
        });
      },
    },
  ];

  return {
    stationCode: stationCode,
    stationType: stationType,
    stationName: capitalizeAll(name),
    island: island ? capitalize(island) : "",
    province: area ? capitalizeWord(area) : "",
    product: (
      <div className={styles.row}>
        <label>
          {stationProduct ? (
            <div className={styles.productList}>
              {stationProduct?.hasOwnProperty(ProductCodeEnum.DIESEL) && stationProduct?.diesel && (
                <div>
                  <div className="flex items-center">
                    {prettifyProductCode(ProductCodeEnum.DIESEL)}
                  </div>
                </div>
              )}
              {stationProduct?.hasOwnProperty(ProductCodeEnum.GAS91) && stationProduct?.gas91 && (
                <div>
                  <div className="flex items-center">
                    {prettifyProductCode(ProductCodeEnum.GAS91)}
                  </div>
                </div>
              )}
              {stationProduct?.hasOwnProperty(ProductCodeEnum.GAS95) && stationProduct?.gas95 && (
                <div>
                  <div className="flex items-center">
                    {prettifyProductCode(ProductCodeEnum.GAS95)}
                  </div>
                </div>
              )}
              {stationProduct?.hasOwnProperty(ProductCodeEnum.GAS97) && stationProduct?.gas97 && (
                <div>
                  <div className="flex items-center">
                    {prettifyProductCode(ProductCodeEnum.GAS97)}
                  </div>
                </div>
              )}
              {stationProduct?.hasOwnProperty(ProductCodeEnum.GAS105) && stationProduct?.gas105 && (
                <div>
                  <div className="flex items-center">
                    {prettifyProductCode(ProductCodeEnum.GAS105)}
                  </div>
                </div>
              )}
              {stationProduct?.hasOwnProperty(ProductCodeEnum.KEROSENE) &&
                stationProduct?.kerosene && (
                  <div>
                    <div className="flex items-center">
                      {prettifyProductCode(ProductCodeEnum.KEROSENE)}
                    </div>
                  </div>
                )}
              {stationProduct?.hasOwnProperty(ProductCodeEnum.LPG) && stationProduct?.lpg && (
                <div>
                  <div className="flex items-center">
                    {prettifyProductCode(ProductCodeEnum.LPG)}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <label>{locale.noProducts}</label>
          )}
        </label>
      </div>
    ),
    map: `${latitude ?? ""}, ${longitude ?? ""}`,
    actions: (
      <PopOverMenu options={options}>
        <MoreVertIcon className={styles.icon} />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { ...fs } = filterState;
  return {
    ...fs,
  };
};
